import { reactive, ref } from 'vue'
import { ElNotification } from 'element-plus'
import ApiService from "@/core/services/ApiService";
import MasterService from "@/core/services/car/MasterService";
import useClipboard from "vue-clipboard3";
import moment from "moment";


let { toClipboard } = useClipboard();

export const notif = reactive({
    simple(title, type, message, duration = 4500) {
        ElNotification({
            customClass: type == 'warning' ? 'notification-warning' : 'notification-success',
            title: title,
            message: message,
            duration: duration,
            type: type,
            dangerouslyUseHTMLString: true
        })
    },

    error(title, message, duration = 0) {
        ElNotification({
            customClass: 'notification-error overlay-center',
            title: title,
            message: message,
            type: 'error',
            dangerouslyUseHTMLString: true,
            duration: duration
        })
    }
})

export const download = reactive({
    file(link) {
        const data = ApiService.query('skye/download', {
            params: {
                url: link
            }
        })

        return data;

    }
})

export const statusModal = reactive({
    showCategory: '',
    isNotValidBrowser: false
})

export const addLog = reactive({
    addDocumentLog(reference_id, action, module) {

        MasterService.addDocumentLog({
            reference_id: reference_id,
            action: action,
            module: module
        });
    }
})

export const listLog = reactive({
    getDocumentLogList(reference_id, module) {
        // console.log('getDocumentLogList', reference_id, module)
        MasterService.getDocumentLogList({
            reference_id: reference_id,
            module: module
        });
    }
})

export const yearList = reactive({
    getYearList() {
        const year = new Date().getFullYear() + 1;
        return Array.from(
            { length: year - 2002 },
            (value, index) => year - index
        );
    },
    getYearValue() {
        const year = new Date().getFullYear() + 1;
        return Array.from(
            { length: year - (year - 4) },
            (value, index) => year - index
        );
    }
})

export const getCurrentDate = reactive({
    today() {
        return (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10);
    }
})

export const validation = reactive({
    validateEmail(email) {
        var reg = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return reg.test(email);
    }
})

export const setBodyPositionFixed = ref({
    isFocused: false,
})
export const currentUser = reactive({
    currentUser: {
        name: "",
        email: "",
        phone_country_code: "+961",
        phone_number: "",
        lead_source: "skye",
        salesAgents: "",
        is_car: 1
    }
})

export const customFunction = reactive({

    async copyLink(link) {
        try {
            await toClipboard(link);
            // console.log("Copied to clipboard");
        } catch (e) {
            // console.error(e);
        }
    }
})

export const getMonthDate = reactive({

    currentDay() {
        var date = new Date();

        return [moment(date).format("YYYY-MM-DD"), moment(date).format("YYYY-MM-DD")];
    },

    previousDay() {
        var date = new Date();
        var previous = new Date(date.getTime());
        previous.setDate(date.getDate() - 1);

        return [moment(previous).format("YYYY-MM-DD"), moment(previous).format("YYYY-MM-DD")];
    },

    currentMonth() {
        var date = new Date();
        var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
        var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);

        return [moment(firstDay).format("YYYY-MM-DD"), moment(lastDay).format("YYYY-MM-DD")];
    },

    lastMonth() {
        var date = new Date();
        var firstDay = new Date(date.getFullYear(), date.getMonth() -1, 1);
        var lastDay = new Date(date.getFullYear(), date.getMonth(), 0);

        return [moment(firstDay).format("YYYY-MM-DD"), moment(lastDay).format("YYYY-MM-DD")];
    },

    previousMonth() {
        var date = new Date();
        var firstDay = new Date(date.getFullYear(), date.getMonth() -2, 1);
        var lastDay = new Date(date.getFullYear(), date.getMonth() -1, 0);

        return [moment(firstDay).format("YYYY-MM-DD"), moment(lastDay).format("YYYY-MM-DD")];
    },

    currentYM() {
        const currentYear = new Date().getFullYear();
        const firstDay = new Date(currentYear, 0, 1)
        return moment(firstDay).format("YYYY-MM");
    }
})

export const getYearDate = reactive({

    currentYear() {

        return [moment().startOf('year').format("YYYY-MM-DD"), moment().endOf('year').format("YYYY-MM-DD")];
    }
})
export const getAdminDashboardHeaders = reactive({
    header: [
        {
            value: 'Months-Year',
            key: 'name'
        },

        {  key: "total_sale", value: "Total Sales" },
        {  key: "total_leads", value: "Total Leads" },
        {  key: "total_closing_ratio", value: "Closing Ratio%" },
        {  key: "total_comp", value: "Comp" },
        {  key: "total_tpl", value: "TPL" },
        {  key: "total_revenue", value: "Total Revenue" },
        {  key: "new_sale", value: "New Sales" },
        {  key: "new_leads", value: "New Leads" },
        {  key: "new_closing_ratio", value: "Closing Ratio%" },
        {  key: "new_comp", value: "Comp" },
        {  key: "new_tpl", value: "TPL" },
        {  key: "new_revenue", value: "Revenue" },
        {  key: "wip_sale", value: "WIP Sales" },
        {  key: "wip_comp", value: "Comp" },
        {  key: "wip_tpl", value: "TPL" },
        {  key: "wip_revenue", value: "Revenue" },
        {  key: "renewal_sale", value: "Renewal Sales" },
        {  key: "renewal_leads", value: "Renewal Leads" },
        {  key: "renewal_closing_ratio", value: "Closing Ratio%" },
        {  key: "renewal_comp", value: "Comp" },
        {  key: "renewal_tpl", value: "TPL" },
        {  key: "renewal_revenue", value: "Revenue" },
        {  key: "llr_sale", value: "Lost Lead Renewal Sales" },
        {  key: "llr_leads", value: "Lost Lead Renewals" },
        {  key: "llr_closing_ratio", value: "Closing Ratio%" },
        {  key: "llr_comp", value: "Comp" },
        {  key: "llr_tpl", value: "TPL" },
        {  key: "llr_revenue", value: "Revenue" },
        {  key: "lost_leads", value: "Lost Leads" },
        {  key: "lrl_leads", value: "Lead Renewal Lost" },
        {  key: "hot_lead", value: "HL" },
        {  key: "return_hot_lead", value: "RHL" },
        {  key: "work_in_progress", value: "WIP" },
        {  key: "ltr_sale", value: "Current Year Renewal Sales" },
        {  key: "ltr_leads", value: "Previous Year Renewal Leads" },
        {  key: "ltr_closing_ratio", value: "Closing Ratio%" },
        {  key: "ltr_comp", value: "Comp" },
        {  key: "ltr_tpl", value: "TPL" },
        {  key: "ltr_revenue", value: "Revenue" },
        {  key: "untouched_customers", value: "Untouched Customers" },
        {  key: "untouched_renewal_leads", value: "Untouched Renewal Leads" },
        {  key: "untouched_new_leads", value: "Untouched New Leads" },
        {  key: "untouched_llr", value: "Untouched LLR" },
        {  key: "total_refunds", value: "Total Refund" },
        {  key: "commission_paid_to_agent", value: "Commission Paid To Agent" },
        {  key: "partial_refund", value: "Partial Refund" },
        {  key: "full_refund", value: "Full Refund" },
        {  key: "total_refund_amount", value: "Total Refund Amount" },
        {  key: "partial_refund_amount", value: "Partial Refund Amount" },
        {  key: "full_refund_amount", value: "Full Refund Amount" },
        {  key: "total_cancellation", value: "Total Cancellation" },
        {  key: "partial_cancellation", value: "Partial Cancellation" },
        {  key: "full_cancellation", value: "Full Cancellation" },
        {  key: "total_cancellation_amount", value: "Total Cancellation Amount" },
        {  key: "partial_cancellation_amount", value: "Partial Cancellation Amount" },
        {  key: "full_cancellation_amount", value: "Full Cancellation Amount" },
        {  key: "total_revenue_inc_addons", value: "Total Revenue (inc Addons)" },
        {  key: "addons", value: "Addons" }
    ]
})

export const updateModal = reactive({
    open: false,
    loading: false,
    name: ''
})

export const leadsData = reactive({
    counts: {},
})


/**
 *  1	Admin
    2	Sales Agent
    3	Policy User
    4	Underwriter
    5	Document Chaser
    6	Accountant
    7	Marketing Agent
    8	Offline Sales Agent
    9	Sales Manager
    10	Online Marketing
    11	Marketing Admin
    12	Sales Admin
    13	Team Leader
    14 QCA
 */
export const roles = reactive({
    dashboard: [1, 2, 4, 6, 7, 9, 11, 13, 14],
    salesDashboard: [1, 2, 6, 9, 11, 13, 14],
    leadsTasks: [1, 9, 13, 2,14],
    customerdetails: [1, 9, 13, 2, 6, 7, 11, 14],
    provideQuotes: [1, 9, 4, 2, 13],
    approvedInvoice: [1, 9, 13, 2, 6, 14],
    amendInvoice: [1, 9, 13, 2],
    forApproval: [1, 9, 13, 2, 6],
    declinedInvoice: [1, 9, 13, 2, 6, 14],
    createManualOrders: [1, 9, 13, 2],
    viewManualOrders: [1, 9, 13, 2, 4, 6],
    policiesAssigned: [1, 9, 13, 2, 4, 6, 14],
    policiesCompleted: [1, 9, 13, 2, 4, 6, 14],
    policiesCancellationRequest: [1, 9, 4],
    policiesApprovedCancellationRefundRequest: [1, 9, 6],
    policiesCancelled: [1, 9, 13, 2, 4, 6,14],
    policiesRefundClosed: [1, 9, 13, 2, 4, 6],
    reportIssuedUploaded: [1, 9, 6, 4,14],
    reportAgentSales: [1, 9, 6],
    reportLoginInfor: [1, 9],
    reportAccountMaster: [1, 6],
    reportLeadSource: [1, 9, 11, 6],
    reportDiscrepancy: [1, 9, 6],
    reportClosedLeads: [1, 9, 11,14],
    reportDeletedLeads: [1,14,9,11],
    users: [1, 9],
    promoCodes: [1],
    salesTarget: [1],
    leadsTarget: [1],
    inquireCallBack: [1, 9],
    inquireContactUs: [1, 9],
    inquireGetQuote: [1, 9],
    alManarah: [1, 6, 14],
    switchRole: [1,9,13],

    userId() {
        const user = JSON.parse(window.localStorage.getItem('user'))
        return user.role_id
    }
})

export const ql = reactive({
    isOpen: false
})

export const ct = reactive({
    isOpen: false,
    fp: false,
    currentLead: 0 
})

/**
 * For setting min and max value that user can enter on car/bike value field. 
 * The max values has been set as 13, so that user can enter decimal(10,2)
 * Validation also added.
 */
export const carBikeValueCheck = reactive({
    min:0,
    max:13,
    validate(value){
        let valSplit  = value.split('.');
        /**
         * The max length is kept as 13 
         * since the decimal will also be a part of the string, 
         * if we kept as 12 user cannot enter decimal(10,2) values 
         */
        let maxLength = (valSplit.length > 1) ? (carBikeValueCheck.max-1) : (carBikeValueCheck.max-3);
        let firstVals = valSplit[0];
        if(firstVals.length > maxLength){
            firstVals = firstVals.slice(0, maxLength);
            firstVals = firstVals.replace(/[^0-9.]/g, '')
        }
        valSplit[0] = firstVals;
        value = valSplit.join('.');
        return value;
    }
})
