import { ref } from 'vue';

export function pageTitle(value) {
    switch(value) {
        case 'edit-quote': return 'Edit Lead Driver Details'
        case 'new-car-quote':
        case 'add-lead-driver-details': return 'Add Lead - Car & Driver Details'
        case 'add-driver-details': return 'Add New Driver Details'
        case 'edit-bike-quote': return 'Edit Bike'
        case 'customer-add-new-bike': return 'Add Lead - Bike Details'
        case 'generate-driver-details-manaual-quote':
        case 'generate-manaual-quote': return 'Generate Quote PDF'
        case 'generate-invoice': return 'Generate New Invoice'
        case 'add-amend-invoice': return 'Amend Type'
        case 'dashboard': return 'Dashboard'
        default: return 'New User'
    }
}

export function setBreadCrumbs(value) {
    switch(value) {
        case 'edit-quote': return "Edit Lead Driver Details"
        case 'new-car-quote':
        case 'add-lead-driver-details': return "Add Lead - Car & Driver Details"
        case 'add-driver-details': return 'Add New Driver Details'
        case 'edit-bike-quote': return 'Edit Bike'
        case 'customer-add-new-bike': return 'Add Lead - Bike Details'
        case 'generate-driver-details-manaual-quote':
        case 'generate-manaual-quote': return 'Generate Quote PDF'
        case 'generate-invoice': return 'Make Invoice'
        case 'customer-details': return 'Customer Details'
        case 'view-policy-details': return 'View Policy Details'
        case 'edit-policy-details': return 'Edit Policy Details'
        case 'customer-list': return 'Leads & Tasks'
        case 'policy-assigned': return 'Policy Assigned'
        case 'policy-completed': return 'Policy Completed'
        case 'add-amend-invoice': return 'Amend Invoice'
        case 'expired-leads': return 'Expired Leads'
        case 'lead-by-source': return 'Lead By Source'
        case 'dashboard-details': return 'Dashboard Details'
        case 'account-master': return 'Account Master'
        case 'admin-report': return 'Admin Report'
        case 'customers-without-lead': return 'Customers Without Lead'
        case 'production-reports': return 'Production Reports'
        case 'marketing-report': return 'Marketing Report'
        case 'marketing-report-agent': return 'Marketing Report By Agent'
        default: return "Add User Details"
    }
}

export function setFromBreadCrumbs(value) {
    switch(value) {
        case 'edit-quote':
        case 'new-car-quote': 
        case 'add-lead-driver-details': 
        case 'edit-bike-quote':
        case 'customer-add-new-bike':
        case 'generate-driver-details-manaual-quote':
        case 'generate-manaual-quote':
        case 'generate-invoice':
        case 'customer-details':
        case 'add-driver-details': return ["Leads & Tasks"]
        case 'view-policy-details':
        case 'policy-assigned':
        case 'policy-completed':
        case 'edit-policy-details': return ['Policy']
        case 'marketing-report':
        case 'marketing-report-agent':
        case 'customer-list': return []
        case 'add-amend-invoice': return ['Invoice']
        case 'expired-leads': return ['Reports']
        case 'lead-by-source': return ['Reports']
        case 'dashboard-details': return ['Reports']
        case 'account-master': return ['Reports']
        case 'admin-report': return ['Reports']
        case 'customers-without-lead': return ['Reports']
        case 'production-reports': return ['Reports']
        default: return ["Leads & Tasks"]
    }
}

export const dashboardInsuranceType = ref(3);
export const dashboardActiveType = ref("today")
