/* eslint-disable no-useless-escape */
import { useRoleBasedConditions } from '@/store/composable/User'

const { salesAgentRequired } = useRoleBasedConditions()

class Validations {
    public static phone_taken = false;
    public static email_taken = false;
    public static email_not_match = false;
    public static phone_not_match = false;
    public static claimed_insurance = false
    public static is_lost_lead_reason = false
    public static task_notes = false
    public static is_reference_no_exist = false
    public static is_doc_limit = false

    
    public static email(rule: any, value: any, callback: any) {
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if(!value) {
            callback(new Error('Please enter email'));
        } 
        else if(!re.test(String(value).toLowerCase())) {
            callback(new Error('Email address must be valid'));
        } 
        else if(Validations.email_taken) {
            callback(new Error('Email address aleardy exist!'));
        }
        else {
            callback();
        }
    }

    public static confirm_email(rule: any, value: any, callback: any) {
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if(!value) {
            callback(new Error('Please enter confirm email'));
        } 
        else if(!re.test(String(value).toLowerCase())) {
            callback(new Error('Email address must be valid'));
        } 
        else if(Validations.email_not_match) {
            callback(new Error('Email address and confirm email not match'));
        }
        else {
            callback();
        }
    }

    public static password(rule: any, value: any, callback: any) {
            
        if(!value) {
            callback(new Error('Password is required'));
          } else if(value.length < 8) {
            callback(new Error('Password minimum of 8 characters'));
          } else {
            callback();
          }
    }

    public static names(rule: any, value: any, callback: any) {
        const isvalidtext = /^[a-zA-Zء-ي\s'-]+$/;
        const singleCount = value ? value.match(/[']/g) : false;
        const minusCount = value ? value.match(/[-]/g) : false;

        let singleCountChecker = true;
        let minusCountChecker = true;

        if(singleCount) {
            singleCountChecker = singleCount.length > 1 ? false : true
        }
        if(minusCount) {
            minusCountChecker = minusCount.length > 1 ? false : true
        }

        if(!value) {
            callback(new Error('Please enter full name'));
        } else if(!isvalidtext.test(String(value).toLowerCase()) || !singleCountChecker || !minusCountChecker) {
            callback(new Error(`Only character a-z, ء-ي, - and ' allowed`))
        } else {
            callback();
        }
    }

    public static other_contact_info(rule: any, value: any, callback: any) {
        if(!value) {
            callback(new Error('Please enter contact information'));
        } else {
            callback();
        }
    }

    public static phone_number(rule: any, value: any, callback: any) {
        if(!value) {
            callback(new Error('Please enter phone number'));
        } else if(value.length < 8 || value.length > 10) {
            callback(new Error('Phone number must not be less than 8 digits'));
        }
         else {
            callback();
        }
    }

    public static confirm_phone_number(rule: any, value: any, callback: any) {
        if(!value) {
            callback(new Error('Please enter confirm phone number'));
        } else if(value.length < 8 || value.length > 10) {
            callback(new Error('Phone number must not be less than 8 digits'));
        } else if(Validations.phone_not_match) {
            callback(new Error('Phone number and confirm phone number not match'));
        }
        else if(Validations.phone_taken) {
            callback(new Error('Phone number aleardy exist!'));
        }
        else {
            callback();
        }
    }

    public static lead_source(rule: any, value: any, callback: any) {
        if(!value) {
            callback(new Error('Lead source is required'));
        }  else {
            callback();
        }
    }

    public static dob(rule: any, value: any, callback: any) {
        
        if(!value) {
            callback(new Error('Please enter date of birth'));
        } else {
            callback();
        }
    }

    public static gender(rule: any, value: any, callback: any) {
        if(!value) {
            callback(new Error('Please select the gender'));
        } else {
            callback();
        }
    }
    public static is_brand_new(rule: any, value: any, callback: any) {
        if(!value) {
            callback(new Error('Please choose any one'));
        } else {
            callback();
        }
    }
    public static is_policy_expired(rule: any, value: any, callback: any) {
        if(!value) {
            callback(new Error('Please choose any one'));
        } else {
            callback();
        }
    }
    public static is_claimed_insurance(rule: any, value: any, callback: any) {
        if(!value) {
            callback(new Error('Please choose any one'));
        } else {
            callback();
        }
    }

    public static nationality(rule: any, value: any, callback: any) {
        if(!value) {
            callback(new Error('Please select the nationality'));
        } else {
            callback();
        }
    }

    public static first_driving_license_country(rule: any, value: any, callback: any) {
        if(!value) {
            callback(new Error('Please select country'));
        } else {
            callback();
        }
    }

    public static age_group(rule: any, value: any, callback: any) {
        if(!value) {
            callback(new Error('Age group is required'));
        } else {
            callback();
        }
    }

    public static insured_city(rule: any, value: any, callback: any) {
        if(!value) {
            callback(new Error('Please select the visa issued emirate'));
        } else {
            callback();
        }
    }

    public static company_name(rule: any, value: any, callback: any) {
        if(!value) {
            callback(new Error('Please enter company name'));
        } else {
            callback();
        }
    }

    public static agent(rule: any, value: any, callback: any) {
        if(!value && salesAgentRequired) {
            callback(new Error('Please select sales agent'));
        } else {
            callback();
        }
    }

    public static notes(rule: any, value: any, callback: any) {
        if(!value) {
            callback(new Error('Please enter notes'));
        } else {
            callback();
        }
    }

    public static requested_to(rule: any, value: any, callback: any) {
        if(!value) {
            callback(new Error('Please select an underwriter'));
        } else {
            callback();
        }
    }

    public static expected_insurance_start_date(rule: any, value: any, callback: any) {
        if(!value) {
            callback(new Error('Please enter expected insurance start date'));
        } else {
            callback();
        }
    }

    public static is_salary(rule: any, value: any, callback: any) {
        if(!value) {
            callback(new Error('Please select about your salary'));
        } else {
            callback();
        }
    }

    public static is_self(rule: any, value: any, callback: any) {
        if(!value) {
            callback(new Error('Please select about your insurance'));
        } else {
            callback();
        }
    }
    
    public static car_year(rule: any, value: any, callback: any) {
        if(!value) {
            callback(new Error('Please select year'));
        } else {
            callback();
        }
    }
    
    public static manufacturer_id(rule: any, value: any, callback: any) {
        if(!value || value == 0) {
            callback(new Error('Please select car make'));
        } else {
            callback();
        }
    }
    
    public static model_id(rule: any, value: any, callback: any) {
        if(!value || value == 0) {
            callback(new Error('Please select car model'));
        } else {
            callback();
        }
    }
    
    public static trim_level_id(rule: any, value: any, callback: any) {
        if(!value || value == 0) {
            callback(new Error('Please select car trim'));
        } else {
            callback();
        }
    }

    public static bike_trim(rule: any, value: any, callback: any) {
        if(!value) {
            callback(new Error('Please select engine capacity'));
        } else {
            callback();
        }
    }
    public static car_value(rule: any, value: any, callback: any) {
        if(!value) {
            callback(new Error('Please enter value'));
        } else {
            callback();
        }
    }
    public static registration_emirate(rule: any, value: any, callback: any) {
        if(['', null, undefined].includes(value)) {
            callback(new Error('Please select emirate'));
        } else {
            callback();
        }
        // callback();
    }
    public static first_registration_date(rule: any, value: any, callback: any) {
        if(!value) {
            callback(new Error('Please select registration date'));
        } else {
            callback();
        }
    }
    public static is_new(rule: any, value: any, callback: any) {
        if(!value) {
            callback(new Error('Please select car is brand new?'));
        } else {
            callback();
        }
    }
    public static gcc_specification(rule: any, value: any, callback: any) {
        if(!value) {
            callback(new Error('Please select gcc specs & no modification'));
        } else {
            callback();
        }
    }
    public static personal_use(rule: any, value: any, callback: any) {
        if(!value) {
            callback(new Error('Please select vehicle use'));
        } else {
            callback();
        }
    }
    public static third_party_liability(rule: any, value: any, callback: any) {
        if(!value) {
            callback(new Error('Please select existing policy TPL'));
        } else {
            callback();
        }
    }
    
    public static policy_start_date(rule: any, value: any, callback: any) {
        if(!value) {
            callback(new Error('Please select policy start date'));
        } else {
            callback();
        }
    }

    public static policy_end_date(rule: any, value: any, callback: any) {
        if(!value) {
            callback(new Error('Please select policy end date'));
        } else {
            callback();
        }
    }

    public static policy_start_date_draft(rule: any, value: any, callback: any) {
        callback();
    }

    public static driving_experience(rule: any, value: any, callback: any) {
        if(!value) {
            callback(new Error('Please select driving experience'));
        } else {
            callback();
        }
    }
    public static uae_driving_experience(rule: any, value: any, callback: any) {
        if(!value) {
            callback(new Error('Please select driving experience'));
        } else {
            callback();
        }
    }
    public static no_of_claims(rule: any, value: any, callback: any) {
        if(value === null || value === '') {
            callback(new Error('Please enter no of claims'));
        } else {
            callback();
        }
    }

    public static claims(rule: any, value: any, callback: any) {
        if(!value && Validations.claimed_insurance) {
            callback(new Error('Please enter no of claims'));
        } else {
            callback();
        }
    }

    public static no_claim_certificate(rule: any, value: any, callback: any) {
        if(!value) {
            callback(new Error('Please select certificate'));
        } else {
            callback();
        }
    }

    public static driver_name(rule: any, value: any, callback: any) {
        if(!value) {
            callback(new Error('Please  enter driver name'));
        } else {
            callback();
        }
    }

    public static transfer_agent(rule: any, value: any, callback: any) {
        if(!value) {
            callback(new Error('Please select agent'));
        } else {
            callback();
        }
    }

    public static transfer_underwriter(rule: any, value: any, callback: any) {
        if(!value) {
            callback(new Error('Please select underwriter'));
        } else {
            callback();
        }
    }
    
    public static email_to(rule: any, value: any, callback: any) {
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if(!value) {
            callback(new Error('Please enter email to'));
        } 
        else if(!re.test(String(value).toLowerCase())) {
            callback(new Error('Email address must be valid'));
        } 
        else {
            callback();
        }
    }
    
    public static subject(rule: any, value: any, callback: any) {
        if(!value) {
            callback(new Error('Please enter subject'));
        } 
        else {
            callback();
        }
    }
    
    public static body(rule: any, value: any, callback: any) {
        if(!value) {
            callback(new Error('Please enter body'));
        } 
        else {
            callback();
        }
    }
    
    public static policy_type(rule: any, value: any, callback: any) {
        if(!value) {
            callback(new Error('Please select policy type'));
        } 
        else {
            callback();
        }
    }
    
    public static companies(rule: any, value: any, callback: any) {
        if(!value || value.length == 0) {
            callback(new Error('Please select companies'));
        } 
        else {
            callback();
        }
    }
    
    public static disposition(rule: any, value: any, callback: any) {
        if(!value || value.length == 0) {
            callback(new Error('Please select response from customer'));
        } 
        else {
            callback();
        }
    }
    
    public static lost_lead_reason(rule: any, value: any, callback: any) {
        if((!value || value.length == 0) && Validations.is_lost_lead_reason) {
            callback(new Error('Please select lost lead reason'));
        } 
        else {
            callback();
        }
    }
    
    public static due_date(rule: any, value: any, callback: any) {
        if((!value || value.length == 0) && Validations.is_lost_lead_reason) {
            callback(new Error('Please enter due date & time'));
        } 
        else {
            callback();
        }
    }
    
    public static task_note(rule: any, value: any, callback: any) {
        if((!value || value.length == 0) && Validations.task_notes) {
            callback(new Error('Please enter due date & time'));
        } 
        else {
            callback();
        }
    }
    
    public static misk_lead_id(rule: any, value: any, callback: any) {
        if(!value) {
            callback(new Error('Please select car leads'));
        } 
        else {
            callback();
        }
    }
    
    public static bai_car_insurance_policy_id(rule: any, value: any, callback: any) {
        if(!value) {
            callback(new Error('Please select insurance plan'));
        } 
        else {
            callback();
        }
    }
    
    public static is_agency_repair(rule: any, value: any, callback: any) {
        if(value == null) {
            callback(new Error('Please select agency repair'));
        } 
        else {
            callback();
        }
    }
    
    public static original_price(rule: any, value: any, callback: any) {
        if(!value) {
            callback(new Error('Please enter policy price'));
        } 
        else {
            callback();
        }
    }
    
    public static sale_price(rule: any, value: any, callback: any) {
        if(!value) {
            callback(new Error('Please enter sale price'));
        } 
        else {
            callback();
        }
    }
    
    public static order_description(rule: any, value: any, callback: any) {
        if(!value) {
            callback(new Error('Please enter order description'));
        } 
        else {
            callback();
        }
    }
    
    public static payment_type(rule: any, value: any, callback: any) {
        if(!value) {
            callback(new Error('Please select payment type'));
        } 
        else {
            callback();
        }
    }
    
    public static language(rule: any, value: any, callback: any) {
        if(!value) {
            callback(new Error('Please select language'));
        } 
        else {
            callback();
        }
    }
    
    public static reference_no(rule: any, value: any, callback: any) {
        if(!value) {
            callback(new Error('Please enter reference no'));
        } else if(Validations.is_reference_no_exist) {
            callback(new Error('Reference no. already exist'))
        }else {
            callback();
        }
    }
    
    public static payment_date(rule: any, value: any, callback: any) {
        if(!value) {
            callback(new Error('Please enter payment date'));
        } 
        else {
            callback();
        }
    }
    
    public static invoice_doc(rule: any, value: any, callback: any) {
        if(!value) {
            callback(new Error('Please upload file for COD / Direct'));
        }  else if(value && Validations.is_doc_limit) {
            callback(new Error('Document size exceed limit of 2mb'))
        } else {
            callback();
        }
    }
    
    public static intransact_policy_id(rule: any, value: any, callback: any) {
        if(!value) {
            callback(new Error('Please select quote ref no for intransact payment type'));
        } 
        else {
            callback();
        }
    }
    
    public static expiry_date_time(rule: any, value: any, callback: any) {
        if(!value) {
            callback(new Error('Please enter expiry date'));
        } 
        else {
            callback();
        }
    }
    
    public static is_discount_given(rule: any, value: any, callback: any) {
        if(value == null) {
            callback(new Error('Discount is given'));
        } 
        else {
            callback();
        }
    }

    public static customer_name(rule: any, value: any, callback: any) {
        if(!value) {
            callback(new Error('Please enter customer name'));
        } 
        else {
            callback();
        }
    }

    public static customer_name_draft(rule: any, value: any, callback: any) {
        callback();
    }

    public static emirates_id(rule: any, value: any, callback: any) {
        if(!value) {
            callback(new Error('Please enter emirates id'));
        } 
        else if(value.length < 18) {
            callback(new Error('Emirates ID must be equal to 18'))
        }
        else {
            callback();
        }
    }

    public static emirates_id_draft(rule: any, value: any, callback: any) {
        if(value && value.length < 18) {
            callback(new Error('Emirates ID must be equal to 18'))
        }
        else {
            callback();
        }
    }

    public static policy_number(rule: any, value: any, callback: any, option: any) {
        if(!value) {
            callback(new Error('Please enter policy number'));
        } else if(value.length < 10) {
            callback(new Error('Policy number must not be less than 10 characters'))
        }
        else {
            callback();
        }
    }

    public static policy_number_draft(rule: any, value: any, callback: any) {
        if(value && value.length < 10) {
            callback(new Error('Policy number must not be less than 10 characters'))
        }
        else {
            callback();
        }
    }

    public static chassis_number(rule: any, value: any, callback: any) {
        const numberContain = /[0-9]/;
        const lettersContain = /[A-Za-z]/;
        if(!value) {
            callback(new Error('Please enter chassis number'));
        } else if(!lettersContain.test(value) || !numberContain.test(value)) {
            callback(new Error('Chassis number must be character or number'))
        }
        else {
            callback();
        }
    }

    public static chassis_number_draft(rule: any, value: any, callback: any) {
        const numberContain = /[0-9]/;
        const lettersContain = /[A-Za-z]/;
        if(value && (!lettersContain.test(value) || !numberContain.test(value))) {
            callback(new Error('Chassis number must be character or number'))
        }
        else {
            callback();
        }
    }

    public static insurance_payment(rule: any, value: any, callback: any) {
        if(!value) {
            callback(new Error('Please select insurance payment'));
        } 
        else {
            callback();
        }
    }

    public static insurance_payment_draft(rule: any, value: any, callback: any) {
        callback();
    }

    public static entity_type(rule: any, value: any, callback: any) {
        if(!value) {
            callback(new Error('Please select entity type'));
        } 
        else {
            callback();
        }
    }

    public static cancellation_type(rule: any, value: any, callback: any) {
        if(!value) {
            callback(new Error('Please select cancellation type'))
        } else {
            callback()
        }
    }

    public static cancellation_credit_amount(rule: any, value: any, callback: any) {
        if(!value) {
            callback(new Error('Credit note'))
        } else {
            callback()
        }
    }

    public static cancellation_credit_notes_no(rule: any, value: any, callback: any) {
        if(!value) {
            callback(new Error('Credit note no.'))
        } else {
            callback()
        }
    }

    public static cancellation_debit_amount(rule: any, value: any, callback: any) {
        if(!value) {
            callback(new Error('Debit note'))
        } else {
            callback()
        }
    }

    public static cancellation_debit_notes_no(rule: any, value: any, callback: any) {
        if(!value) {
            callback(new Error('Debit note no.'))
        } else {
            callback()
        }
    }

    public static cancellation_endorsement_no(rule: any, value: any, callback: any) {
        if(!value) {
            callback(new Error('Endorsement no.'))
        } else {
            callback()
        }
    }

    public static cancellation_reason(rule: any, value: any, callback: any) {
        if(!value) {
            callback(new Error('Please select cancellation reason'))
        } else {
            callback()
        }
    }

    public static cancellation_note(rule: any, value: any, callback: any) {
        if(!value) {
            callback(new Error('Please enter cancellation notes'))
        } else {
            callback()
        }
    }

    public static cancellation_date(rule: any, value: any, callback: any) {
        if(!value) {
            callback(new Error('Please enter cancellation date on invoice'))
        } else {
            callback()
        }
    }

    public static underwriter_cancellation_notes(rule: any, value: any, callback: any) {
        if(!value) {
            callback(new Error('Please enter underwriter notes'))
        } else {
            callback()
        }
    }

    public static cancellation_status_id(rule: any, value: any, callback: any) {
        if(!value || value === 6) {
            callback(new Error('Please select status'))
        } else {
            callback()
        }
    }

    public static pay_to(rule: any, value: any, callback: any) {
        if(!value) {
            callback(new Error('Please select pay to'))
        } else {
            callback()
        }
    }

    public static policy_status(rule: any, value: any, callback: any) {
        if(!value) {
            callback(new Error('Please select policy status'))
        } else {
            callback()
        }
    }

    public static refund_account_note(rule: any, value: any, callback: any) {
        if(!value) {
            callback(new Error('Please enter account notes'))
        } else {
            callback()
        }
    }

    public static refund_status(rule: any, value: any, callback: any) {
        if(!value || value <= 1) {
            callback(new Error('Please select status'))
        } else {
            callback()
        }
    }

    public static refund_type(rule: any, value: any, callback: any) {
        if(!value) {
            callback(new Error('Please select refund type'))
        } else {
            callback()
        }
    }

    public static refund_reason(rule: any, value: any, callback: any) {
        if(!value) {
            callback(new Error('Please select refund reason'))
        } else {
            callback()
        }
    }

    public static refund_note(rule: any, value: any, callback: any) {
        if(!value) {
            callback(new Error('Please enter refund notes'))
        } else {
            callback()
        }
    }

    public static sum_insured(rule: any, value: any, callback: any) {
        if(value === null || value === undefined || value == '') {
            callback(new Error('Please enter sum insured'))
        } else {
            callback()
        }
    }

    public static sum_insured_draft(rule: any, value: any, callback: any) {
        callback();
    }

    public static policy_required_doc(rule: any, value: any, callback: any) {
        if(!value) {
            callback(new Error('Kindly upload the required documents before you finally submit it for policy completion.'))
        } else {
            callback()
        }
    }

    public static entity_type_draft(rule: any, value: any, callback: any) {
        callback();
    }

    public static policy_required_doc_draft(rule: any, value: any, callback: any) {
        callback();
    }

    public static manualPolicy(rule: any, value: any, callback: any) {
        if(!value) {
            callback(new Error('Please select policy document'));
        } else {
            callback();
        }
    }

}
/**
 * create custom validations
 * @created vincent carabbacan
 */
export default Validations;
