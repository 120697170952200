const accordions = [
    {
        label: 'LEAD STATUS: &nbsp;',
        key: 'lead_status',
        acn: 'd-flex', 
        cn: 'badge',
        isMixin: true,
        size: 5
    },
    {
        label: '',
        key: 'name',
        acn: '', 
        size: 4
    },
    {
        label: 'Start Dt: ',
        key: 'start_date',
        acn: '', 
        cn: 'mx-2 fw-light ms-1 fw-boldest',
        size: 4
    },
    {
        label: 'End Dt: ',
        key: 'end_date',
        acn: '', 
        cn: 'mx-2 fw-light ms-1 fw-boldest',
        size: 4
    },
    // {
    //     label: 'Coverage Area: ',
    //     key: 'coverage_area',
    //     acn: '', 
    //     cn: 'fw-light ms-1 fw-boldest',
    //     size: 5
    // },
    {
        label: 'Policy: ',
        key: 'policy_bought',
        acn: 'd-flex justify-content-center', 
        cn: 'mx-2 fw-light fw-boldest',
        size: 3
    },
    {
        label: '',
        key: 'agent_name',
        acn: 'd-flex justify-content-end', 
        cn: 'mx-4 fw-light fw-boldest',
        size: 4
    },
]

const timelines = [
    {
        label: 'Current Visit Date',
        value: 'current_visited_date',
        cn: 'text-primary'
    },
    {
        label: 'Last Visit Date',
        value: 'last_visited_date',
        cn: 'text-danger'
    },
    {
        label: 'Created Date',
        value: 'created_at',
        cn: 'text-warning'
    },
]

const details = [
    {
        title: `Lead ID:`,
        showTitle: true,
        subTitle: 'SUB_TITLE',
        showSubTitle: true,
        inTable: true,
        key: 'lead',
        items: [
            {
                label: 'MISKINSURANCE Details',
                value: 'vehicle_details',
                cn: '',
                cnh: 'ps-3',
                width: '400',
                lwidth: '400',
            },
            {
                label: 'Insured For',
                value: 'insured_for',
                cn: '',
                cnh: 'ps-3',
                width: '200'
            },
            {
                label: 'Lead Status',
                value: 'lead_status',
                cn: 'fw-bolder fw-6 badge',
                cnh: '',
                isMixin: true,
                width: '150'
            },
            // {
            //     label: 'Due At',
            //     value: 'due_at',
            //     cn: '',
            //     cnh: 'ps-3',
            //     width: '200'
            // },
            {
                label: 'Application Status',
                value: 'application_status',
                cn: '',
                cnh: 'ps-3',
                isPolicyDocument: true,
                width: '200'
            },
            {
                label: 'Request Timeline',
                value: 'request_time_line',
                cn: '',
                cnh: 'ps-3',
                isTimeLine: true,
                width: '150'
            },
            {
                label: 'Lead Source',
                value: 'lead_source',
                cn: '',
                cnh: 'ps-3',
                isTooltip: true,
                width: '200'
            },
        ]
    },
    {
        title: `Payment Detail`,
        showTitle: true,
        subTitle: '',
        showSubTitle: false,
        inTable: true,
        key: 'payment_details',
        items: [
            {
                label: 'Quote No.',
                value: 'bai_ref_no',
                cnd: 'customer-first-column',
            },
            {
                label: 'Payable Amt ($)',
                value: 'sale_price',
                cnd: 'customer-second-column',
            },
            {
                label: 'Transaction Type',
                value: 'payment_mode',
                cnd: 'customer-third-column'
            },
            {
                label: 'Date',
                value: 'approved_date',
                cnd: 'customer-third-column'
            },
        ]
    },
    {
        title: 'Details of Traveller',
        showTitle: true,
        subTitle: 'Traveller TRAVELER_COUNT',
        showSubTitle: true,
        inTable: false,
        key: 'traveller_detail',
        items: [
            {
                label: 'First Name',
                value: 'first_name',
                cnd: 'customer-first-column'
            },
            {
                label: 'Middle Name',
                value: 'middle_name',
                cnd: 'customer-second-column'
            },
            {
                label: 'Last Name',
                value: 'last_name',
                cnd: 'customer-third-column'
            },
            {
                label: 'Date of Birth',
                value: 'dob',
                cnd: 'customer-first-column'
            },
            {
                label: 'Gender',
                value: 'gender',
                cnd: 'customer-second-column'
            },
            {
                label: 'Passport No.',
                value: 'passport',
                cnd: 'customer-third-column'
            },
            {
                label: 'Country of Residence',
                value: 'country_of_residence',
                cnd: 'customer-first-column'
            },
            {
                label: 'Nationality',
                value: 'traveller_nationality',
                cnd: 'customer-second-column'
            },
        ]
    },
    {
        title: 'Additional Details for the VEHICLE',
        showTitle: true,
        subTitle: '',
        showSubTitle: false,
        inTable: false,
        key: 'customer_detail',
        items: [
            {
                label: 'Estimated VEHICLE Value (CURRENCY)',
                value: 'estimated_value',
                cnd: 'customer-first-column'
            },
            {
                label: 'VEHICLE is Brand New',
                value: 'is_new',
                cnd: 'customer-second-column'
            },
            {
                label: 'Registration Emirate',
                value: 'registration_emirate',
                cnd: 'customer-third-column'
            },
            {
                label: 'VEHICLE first registration date',
                value: 'first_registration_date',
                cnd: 'customer-first-column'
            },
            {
                label: 'Policy Active',
                value: 'policy_active',
                cnd: 'customer-second-column'
            },
            {
                label: 'Expected Policy',
                value: 'expected_policy',
                cnd: 'customer-third-column'
            },
            {
                label: 'GCC Specs & No Modify',
                value: 'gcc_spec',
                cnd: 'customer-first-column'
            },
            {
                label: 'Vehicle Use',
                value: 'vehicle_use',
                cnd: 'customer-second-column'
            },
            {
                label: 'Existing Policy TPL Only',
                value: 'existing_policy',
                cnd: 'customer-third-column'
            },
        ]
    },
    {
        title: 'Driver Details',
        showTitle: true,
        subTitle: '',
        showSubTitle: false,
        inTable: false,
        key: 'driver_details',
        items: [
            {
                label: 'DOB',
                value: 'dob',
                cnd: 'customer-first-column'
            },
            {
                label: 'Driver Nationality',
                value: 'nationality',
                cnd: 'customer-second-column'
            },
            {
                label: 'First DL was issued in',
                value: 'first_license_country',
                cnd: 'customer-third-column'
            },
            {
                label: 'Intl. Driving Experience',
                value: 'driving_experience',
                cnd: 'customer-first-column'
            },
            {
                label: 'UAE Driving Experience',
                value: 'uae_driving_experience',
                cnd: 'customer-second-column'
            },
            {
                label: 'Driver Name',
                value: 'driver_name',
                isIcon: true,
                cnd: 'customer-third-column'
            },
            {
                label: 'Claims Made',
                value: 'claims_made',
                cnd: 'customer-first-column'
            },
            {
                label: 'Number of Claims',
                value: 'no_claims',
                cnd: 'customer-second-column'
            },
            {
                label: 'No Claims Certificate',
                value: 'no_claim_certificate',
                cnd: 'customer-third-column'
            },
        ]
    },
    {
        title: 'Policy Details',
        showTitle: true,
        subTitle: '(Status: STATUS_VALUE | ACTIVE_STATUS_VALUE)',
        showSubTitle: true,
        inTable: false,
        key: 'policy_details',
        items: [
            {
                label: 'Policy Expiry Date',
                value: 'policy_expiry_date',
                cnd: 'customer-first-column'
            },
            {
                label: 'Quote No.',
                value: 'quote_no',
                cnd: 'customer-second-column'
            },
            {
                label: 'Policy Name',
                value: 'policy_name',
                cnd: 'customer-third-column'
            },
            {
                label: 'Policy Type',
                value: 'policy_type',
                cnd: 'customer-first-column'
            },
            {
                label: 'Policy Premium',
                value: 'premium',
                cnd: 'customer-second-column'
            },
            {
                label: 'Registration Emirates',
                value: 'registration_emirate',
                isIcon: true,
                cnd: 'customer-third-column'
            },
            {
                label: 'VEHICLE Value (CURRENCY)',
                value: 'car_value',
                cnd: 'customer-first-column'
            },
            {
                label: 'Chassis Number',
                value: 'chassis_no',
                cnd: 'customer-second-column'
            },
            {
                label: 'Transaction Type',
                value: 'transaction_type',
                cnd: 'customer-third-column'
            },
            {
                label: 'Policy SA',
                value: 'policy_sa',
                cnd: 'customer-first-column'
            },
            {
                label: 'Underwriter',
                value: 'underwriter',
                cnd: 'customer-second-column'
            },
            {
                label: 'Policy Issued On',
                value: 'policy_issued_on',
                isViewDocument: true,
                cnd: 'customer-third-column'
            },
        ]
    },
]

const customer_details = [
    {
        label: '',
        value: 'name',
        isEdit: true,
        size: 2,
        action: 'edit_name'
    },
    {
        label: '',
        value: 'email',
        isEdit: true,
        size: 2,
        action: 'edit_email'
    },
    {
        label: '',
        value: 'complete_number',
        isEdit: true,
        isWhatsapp: true,
        size: 2,
        action: 'edit_phone'
    },
    {
        label: '',
        value: 'email_customer',
        isEmail: true,
        size: 1,
        action: 'edit_other_info'
    },
    {
        label: '',
        value: 'customer_status_text',
        isBadge: true,
        size: 2
    },
    // {
    //     label: '',
    //     value: 'agent_name',
    //     class: 'agent_name_ml',
    //     size: 1
    // },
    {
        label: 'Tasks #:',
        value: 'open_task_count',
        size: 1
    },
    {
        label: 'Customer Log',
        value: 'customer_log',
        class: 'agent_name_ml',
        isClick: true,
        size: 1,
        action: 'customer_logs'
    },
]

const lead_tabs = [
    {
        label: 'Lead',
        value: 'lead',
        active: true,
        withCount: true
    },
    {
        label: 'View Details',
        value: 'view_details',
        active: false,
        withDisable: true
    },
    {
        label: 'Tasks',
        value: 'tasks',
        active: false
    },
    {
        label: 'Notes',
        value: 'notes',
        active: false
    },
    // {
    //     label: 'Policies',
    //     value: 'policies',
    //     active: false,
    //     withDisable: true
    // },
    {
        label: 'Emails',
        value: 'emails',
        active: false
    },
    // {
    //     label: 'Documents',
    //     value: 'documents',
    //     active: false,
    //     withDisable: true
    // },
    // {
    //     label: 'View Quotes',
    //     value: 'view_quotes',
    //     active: false,
    //     withDisable: true
    // },
    // {
    //     label: 'SMS',
    //     value: 'sms',
    //     active: false
    // },
    {
        label: 'Lead Activity',
        value: 'lead_activity',
        active: false
    },
]

const view_tasks = [
    {
        label: 'Current Data',
        value: 'current_data',
        withCondition: false
    },
    {
        label: 'Lost Lead Reason',
        value: 'lost_lead_reason',
        withCondition: true
    },
    {
        label: 'Lead Status',
        value: 'lead_status',
        withCondition: false
    },
    {
        label: 'Task Note',
        value: 'task_note',
        withCondition: false
    },
    {
        label: 'Due at',
        value: 'due_at',
        withCondition: false
    },
    {
        label: 'By',
        value: 'by',
        withCondition: false
    },
    {
        label: 'Closed at',
        value: 'closed_at',
        withCondition: false
    },
]

const leads_tasks = [
    {
        label: 'Name',
        value: 'name',
        width: '200',
        isRoute: true
    },
    {
        label: 'Email <br />Mobile Number',
        value: 'email_mobile',
        width: '200',
        isCopy: true
    },
    {
        label: 'User Type',
        value: 'customer_status_text',
        width: '250',
        isRoute: true,
        withMix: true
    },
    {
        label: 'Is Renewal',
        value: 'is_renewal',
        width: '120',
    },
    {
        label: 'Task #',
        value: 'open_task_count',
        width: '80',
    },
    {
        label: 'Task Due Date',
        value: 'task_due_date',
        width: '200',
        isTimeLine: true
    },
    {
        label: 'Sales Agent',
        value: 'sales_agent',
        width: '150',
        isRoute: true
    },
    {
        label: 'Select to Transfer',
        value: 'customer_id',
        width: '120',
        isTransfer: true
    },
]


const customer_log = [
    {
        label: 'Customer Activity Log',
        value: 1
    },
    {
        label: 'Customer Status Log',
        value: 2
    },
    {
        label: 'Customer View Activity Log',
        value: 3
    },
]

const activity_logs = [
    {
        label: 'Current Data',
        value: 'current_data',
        withCondition: 'showCurrentData'
    },
    {
        label: 'Previous Data',
        value: 'previous_data',
        withCondition: 'showPrevData'
    },
    {
        label: 'By',
        value: 'by',
        withCondition: 'showBy'
    },
]

const status_logs = [
    {
        label: 'Current Status',
        value: 'current_status',
        isBold: true
    },
    {
        label: 'Previous Status',
        value: 'previous_status'
    },
    {
        label: 'By',
        value: 'by'
    },
]

const lead_activity = [
    {
        label: 'Lead Activity Log',
        value: 1
    },
    {
        label: 'Lead Status Log',
        value: 2
    }
]

const lead_policy = [
    {
        label: 'Policies List',
        value: 1
    },
    {
        label: 'Policy Log',
        value: 2,
        isCondition: true
    }
]

const documents = [
    {
        label: 'View Documents',
        value: 1
    },
    {
        label: 'Upload Documents',
        value: 2,
        isCondition: true
    },
]

const policy_log_tab = [
    {
        label: 'Policy Status',
        value: 'policy_status'
    },
    {
        label: 'Action',
        value: 'action'
    },
    {
        label: 'Current Data',
        value: 'current_data'
    },
    {
        label: 'Previous Data',
        value: 'previous_data'
    },
    {
        label: 'By',
        value: 'by'
    },
]

const lead_buttons = [
    {
        label: 'New Driver',
        icon: 'las la-plus fs-3 text-white',
        class: 'p-2 btn-info cursor-pointer btn-info-custom',
        car: 'add-driver-details',
        bike: 'add-driver-details',
        action: 'new-driver',
        condition: 'showNewDriver'
    },
    {
        label: 'New Car',
        icon: 'las la-plus fs-3 text-white',
        class: 'p-2 btn-info cursor-pointer px-4 btn-info-custom',
        car: 'new-car-quote',
        bike: 'new-car-quote',
        action: 'new-car',
        condition: 'showNewCar'
    },
    {
        label: 'Add Details',
        icon: '',
        class: 'p-2 btn-info cursor-pointer px-4 btn-info-custom',
        car: 'add-lead-driver-details',
        bike: 'customer-add-new-bike',
        action: 'add-details',
        condition: 'showAddDetails'
    },
    {
        label: 'View Tasks',
        icon: '',
        class: 'p-2 btn-info cursor-pointer px-4 btn-info-custom',
        car: 'add-lead-driver-details',
        bike: 'customer-add-new-bike',
        action: 'view-tasks',
        condition: 'showViewTasks'
    }
]

const quotes_actions = [
    {
        label: 'Edit Lead Details',
        icon: 'icon-svg icon-edit',
        class: 'btn-active-color-primary btn-sm dropdown-item me-1',
        car: 'edit-quote',
        bike: 'edit-bike-quote',
        action: 'edit-lead-details',
        condition: 'showEditLeadDetails'
    },
    {
        label: 'Add Lead - VEHICLE & Driver Details',
        icon: 'icon-svg icon-add-lead',
        class: 'btn-active-color-primary btn-sm dropdown-item me-1',
        car: 'add-lead-driver-details',
        bike: 'customer-add-new-bike',
        action: 'add-lead-details',
        condition: 'showAddLeadDetails'
    },
    {
        label: 'Generate Quote PDF',
        icon: 'far fa-file-pdf',
        class: 'btn-active-color-primary btn-sm dropdown-item me-1',
        car: 'generate-driver-details-manaual-quote',
        bike: 'generate-driver-details-manaual-quote',
        action: 'generate-quote-pdf',
        condition: 'showGenerateQuotePdf'
    },
    {
        label: 'Upload Documents',
        icon: 'icon-svg icon-upload',
        class: 'btn-active-color-primary btn-sm dropdown-item me-1',
        car: '',
        bike: '',
        action: 'upload-documents',
        condition: 'showUploadDocuments'
    },
    {
        label: 'Compare Quotes',
        icon: 'icon-svg icon-compare-quotes',
        class: 'btn-active-color-primary btn-sm dropdown-item me-1',
        car: '',
        bike: '',
        action: 'compare-quotes',
        condition: 'showCompareQuotes'
    },
    {
        label: 'Initiate Quotes',
        icon: 'icon-svg icon-add-travel-blue icon-size-small',
        class: 'btn-active-color-primary btn-sm dropdown-item me-1',
        car: '',
        bike: '',
        action: 'initiate-quotes',
        condition: 'showInitiateQuotes'
    },
    {
        label: 'Initiate Quotes',
        icon: 'icon-svg icon-add-travel-blue icon-size-small',
        class: 'btn-active-color-primary btn-sm dropdown-item me-1',
        car: '',
        bike: '',
        action: 'review-details',
        condition: 'showReviewMisk'
    },
    {
        label: 'Compare Quotes',
        icon: 'icon-svg icon-compare-quotes',
        class: 'btn-active-color-primary btn-sm dropdown-item me-1',
        car: '',
        bike: '',
        action: 'compare-quotes-misk',
        condition: 'showCompareQuotesMisk'
    },
    {
        label: 'Send Quote',
        icon: 'fas fa-envelope-open-text',
        class: 'btn-active-color-primary btn-sm dropdown-item me-1',
        car: '',
        bike: '',
        action: 'send-quote',
        condition: 'showSendQuote'
    },
    {
        label: 'Make Invoice',
        icon: 'fas fa-coins',
        class: 'btn-active-color-primary btn-sm dropdown-item me-1',
        car: 'generate-invoice',
        bike: 'generate-invoice',
        action: 'make-invoice',
        condition: 'showMakeInvoice'
    },
    {
        label: 'approve_payment_text',
        icon: 'fas fa-coins',
        class: 'btn-active-color-primary btn-sm dropdown-item me-1',
        car: '',
        bike: '',
        action: 'approve_cash_payment',
        condition: 'showApproveInvoice'
    },
    {
        label: 'Upload Policy',
        icon: 'icon-svg icon-upload',
        class: 'btn-active-color-primary btn-sm dropdown-item me-1',
        car: '',
        bike: '',
        action: 'upload_manual_policy',
        condition: 'showUploadPolicy'
    },
    {
        label: 'Transfer Lead',
        icon: 'far fa-paper-plane icon-size icon-blue',
        class: 'btn-active-color-primary btn-sm dropdown-item me-1',
        car: '',
        bike: '',
        action: 'transfer_leads',
        condition: 'showTransferLead'
    },
    {
        label: 'Close Lead',
        icon: 'icon-svg icon-close',
        class: 'btn-active-color-primary btn-sm dropdown-item me-1',
        car: '',
        bike: '',
        action: 'close_lead_misk',
        condition: 'showCloseLead'
    }
]

const make_invoice_payment = [
    {
        label: 'COD',
        value: 2
    },
    // {
    //     label: 'Direct',
    //     value: 3
    // },
    {
        label: 'Online',
        value: 1
    },
    {
        label: 'OMT',
        value: 5
    },
    // {
    //     label: 'Intransact',
    //     value: 4
    // },
]

const yesNo = [
    {
        label: 'Yes',
        value: 1
    },
    {
        label: 'No',
        value: 0
    },
]

const languages = [
    {
        label: 'English',
        value: 'en'
    },
    {
        label: 'Arabic',
        value: 'ar'
    },
]


export {
    languages,
    yesNo,
    make_invoice_payment,
    quotes_actions,
    lead_buttons,
    documents,
    lead_activity,
    lead_policy,
    customer_log,
    activity_logs,
    status_logs,
    leads_tasks,
    lead_tabs,
    accordions,
    details,
    timelines,
    customer_details,
    view_tasks,
    policy_log_tab
}