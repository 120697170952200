/**
 * Reports Module.
 * Contains Below Reports
 * All reports from Reports Section
 * 
 * Learning Note: Register every new module in store/index.ts if it is not registered.
 */

import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Mutation, Action, Module, VuexModule } from "vuex-module-decorators";
import ApiService from "@/core/services/ApiService";

/**
 * Importing some related interfaces.
 */
import IReportPolicy from "@/models/reports/car/IReportPolicy";
import IReportAgentSales from "@/models/reports/car/IReportAgentSales";
import IExport from "@/models/reports/car/IExport";
import IPaginationInfo from "@/models/IPaginationInfo";
import moment from "moment";
import IReportSkyeLoginInfo from "@/models/IReportSkyeLoginInfo";
import IReportAccountMaster from "@/models/reports/car/IReportAccountMaster";
import IReportLeadBySource from "@/models/reports/car/IReportLeadBySource";

/**
 * Interface for API's payloads (bodyParams).
 */
interface IPayload {
    start_date: string,
    end_date: string,
    status: any,
    agent: any,
    user_id: any, // for skye login info report only
    per_page: number | string,
    page: number,
    transaction_type: any, // for account master report only
    keyword: string, // for account master report only
}

// IPayLoadLBS is only for lead By Source (LBS)
interface IPayloadLBS {
    year: string,
    month: string,
    lead_source: string,
    utm_source: string,
    utm_medium: string, 
    utm_campaign: string, 
    utm_content: string,
    per_page: number | string,
    page: number,
}

interface IKeyword {
    keyword: string,
}

interface IRefundRecordCount {
    'refund_record_count' : number
}

/**
 * Starting Module
 */
@Module
export default class ReportModule extends VuexModule {
    errors = [];
    paginationObj = {
        current_page: 1,
        data: [],
        first_page_url: "",
        from: 1,
        last_page: 1,
        last_page_url: "",
        links: [],
        next_page_url: "",
        path: "",
        per_page: "",
        prev_page_url: "",
        to: 1,
        total: 1,
    } as IPaginationInfo;
    export = {} as IExport;

    almanarahResponse = {};

    commonPayLoad = {
        start_date: moment().startOf("month").format("YYYY-MM-DD"),
        end_date: moment().endOf("month").format("YYYY-MM-DD"),
        status: [],
        agent: [],
        user_id: [],
        per_page: 50,
        page: 1,
        transaction_type: [],
        keyword: "",
    } as IPayload;

    commonPayLoadLBS = {
        year: "",
        month: "",
        lead_source: "",
        utm_source: "",
        utm_medium: "",
        utm_campaign: "",
        utm_content: "",
        per_page: 50,
        page: 1,
    } as IPayloadLBS;
    
    keywordPayload = {
        keyword: "",
    } as IKeyword;

    policyReportList = [] as Array<IReportPolicy>;
    agentSalesReportList = [] as Array<IReportAgentSales>;
    skyeLoginInfoReportList = [] as Array<IReportSkyeLoginInfo>;
    accountMasterReportList = [] as Array<IReportAccountMaster>;
    accountMasterRefundRecordCount = {} as IRefundRecordCount;
    quoteAutoSuggestList = [] as Array<string>;
    leadBySourceReportList = [] as Array<IReportLeadBySource>;
    leadBySourceFiltersList = [] as Array<any>;
    getReportYears = [] as Array<any>;
    getReportMonths = [] as Array<any>;
    getReportLeadSources = [] as Array<any>;
    getReportUTMSources = [] as Array<any>;
    getReportUTMMedium = [] as Array<any>;
    getReportUTMCampaign = [] as Array<any>;
    getReportUTMContent = [] as Array<any>;
    closedLeadReportList = [] as Array<any>;
    alManarahReportList = [] as Array<any>;
    deletedLeadReportList = [] as Array<any>;
    customerWithoutLeadReportList = [] as Array<any>;
    discrepancyReportList = [] as Array<any>;
    adminDashboardData = [] as Array<any>;
    adminDashboardExportUrl = [] as Array<any>;
    reportLeadStatuses = [] as Array<any>;
    dashboardDetailsData = [] as Array<any>;
    arLoading = false;

    // Action - GET POLICY REPORT LIST
    @Action
    [Actions.GET_POLICY_REPORT_LIST](payload) {
        this.context.commit(Mutations.SET_AI_LOADING, true);
        return new Promise<any>((resolve, reject) => {

            const url = "/skye/reports/policies-assigned-vs-completed?page=" + payload.page;

            ApiService.post(url, payload)
                .then(({ data }) => {
                    this.context.commit(Mutations.SET_AI_LOADING, false);
                    this.context.commit(Mutations.SET_REPORT_ERROR, []);
                    this.context.commit(Mutations.SET_POLICY_REPORT_LIST, data.data.policies);
                    resolve(data);
                })
                .catch(({ response }) => {
                    this.context.commit(Mutations.SET_AI_LOADING, false);
                    this.context.commit(Mutations.SET_REPORT_ERROR, response);
                    reject(response);
                });
        });
    }

    // Action - EXPORT POLICY REPORT LIST
    @Action
    [Actions.EXPORT_POLICY_REPORT_LIST](payload) {
        this.context.commit(Mutations.SET_AI_LOADING, true);
        return new Promise<any>((resolve, reject) => {

            const url = "/skye/reports/exports/policies-assigned-vs-completed?page=" + payload.page;

            this.context.commit(Mutations.SET_DISABLE_BUTTON, true);
            ApiService.post(url, payload)
                .then(({ data }) => {
                    this.context.commit(Mutations.SET_AI_LOADING, false);
                    this.context.commit(Mutations.SET_REPORT_ERROR, []);
                    this.context.commit(Mutations.SET_EXPORT_POLICY_REPORT_LIST, data.data.data);
                    this.context.commit(Mutations.SET_DISABLE_BUTTON, false);

                    resolve(data);
                })
                .catch(({ response }) => {
                    this.context.commit(Mutations.SET_AI_LOADING, false);
                    this.context.commit(Mutations.SET_REPORT_ERROR, response);
                    reject(response);
                });
        });
    }

    // Action - GET AGENT SALES REPORT LIST
    @Action
    [Actions.GET_AGENT_SALES_REPORT_LIST](payload) {
        this.context.commit(Mutations.SET_AI_LOADING, true);
        return new Promise<any>((resolve, reject) => {

            const url = "/skye/reports/agent-sales?page=" + payload.page;

            ApiService.post(url, payload)
                .then(({ data }) => {
                    this.context.commit(Mutations.SET_AI_LOADING, false);
                    this.context.commit(Mutations.SET_REPORT_ERROR, []);
                    this.context.commit(Mutations.SET_AGENT_SALES_REPORT_LIST, data.data.report);
                    resolve(data);
                })
                .catch(({ response }) => {
                    this.context.commit(Mutations.SET_AI_LOADING, false);
                    this.context.commit(Mutations.SET_REPORT_ERROR, response);
                    reject(response);
                });
        });
    }

    // Action - EXPORT AGENT SALES REPORT LIST
    @Action
    [Actions.EXPORT_AGENT_SALES_REPORT_LIST](payload) {
        payload.page = 1;
        this.context.commit(Mutations.SET_AI_LOADING, true);
        return new Promise<any>((resolve, reject) => {

            const url = "/skye/reports/exports/agent-sales?page=" + payload.page;

            this.context.commit(Mutations.SET_DISABLE_BUTTON, true);
            ApiService.post(url, payload)
                .then(({ data }) => {
                    this.context.commit(Mutations.SET_AI_LOADING, false);
                    this.context.commit(Mutations.SET_REPORT_ERROR, []);
                    this.context.commit(Mutations.SET_EXPORT_AGENT_SALES_REPORT_LIST, data.data.data);
                    this.context.commit(Mutations.SET_DISABLE_BUTTON, false);

                    resolve(data);
                })
                .catch(({ response }) => {
                    this.context.commit(Mutations.SET_AI_LOADING, false);
                    this.context.commit(Mutations.SET_REPORT_ERROR, response);
                    reject(response);
                });
        });
    }

    // Action - GET SKYE LOGIN INFO REPORT LIST
    @Action
    [Actions.GET_SKYE_LOGIN_INFO_REPORT_LIST](payload) {
        this.context.commit(Mutations.SET_AI_LOADING, true);
        return new Promise<any>((resolve, reject) => {

            const url = "/skye/reports/login-info?page=" + payload.page;

            ApiService.post(url, payload)
                .then(({ data }) => {
                    this.context.commit(Mutations.SET_AI_LOADING, false);
                    this.context.commit(Mutations.SET_REPORT_ERROR, []);
                    this.context.commit(Mutations.SET_SKYE_LOGIN_INFO_REPORT_LIST, data.data.report);
                    resolve(data);
                })
                .catch(({ response }) => {
                    this.context.commit(Mutations.SET_AI_LOADING, false);
                    this.context.commit(Mutations.SET_REPORT_ERROR, response);
                    reject(response);
                });
        });
    }

    // Action - EXPORT SKYE LOGIN INFO REPORT LIST
    @Action
    [Actions.EXPORT_SKYE_LOGIN_INFO_REPORT_LIST](payload) {
        this.context.commit(Mutations.SET_AI_LOADING, true);
        return new Promise<any>((resolve, reject) => {

            const url = "/skye/reports/exports/login-info?page=" + payload.page;

            this.context.commit(Mutations.SET_DISABLE_BUTTON, true);
            ApiService.post(url, payload)
                .then(({ data }) => {
                    this.context.commit(Mutations.SET_AI_LOADING, false);
                    this.context.commit(Mutations.SET_REPORT_ERROR, []);
                    this.context.commit(Mutations.SET_EXPORT_SKYE_LOGIN_INFO_REPORT_LIST, data.data.data);
                    this.context.commit(Mutations.SET_DISABLE_BUTTON, false);

                    resolve(data);
                })
                .catch(({ response }) => {
                    this.context.commit(Mutations.SET_AI_LOADING, false);
                    this.context.commit(Mutations.SET_REPORT_ERROR, response);
                    reject(response);
                });
        });
    }

    // Action - GET ACCOUNT MASTER REPORT LIST
    @Action
    [Actions.GET_ACCOUNT_MASTER_REPORT_LIST](payload) {
        this.context.commit(Mutations.SET_AI_LOADING, true);
        return new Promise<any>((resolve, reject) => {

            const url = "/skye/reports/account-master?page=" + payload.page;

            ApiService.post(url, payload)
                .then(({ data }) => {
                    this.context.commit(Mutations.SET_AI_LOADING, false);
                    this.context.commit(Mutations.SET_REPORT_ERROR, []);
                    this.context.commit(Mutations.SET_ACCOUNT_MASTER_REPORT_LIST, data.data.report);
                    this.context.commit(Mutations.SET_ACCOUNT_MASTER_REFUND_RECORD_COUNT, data.data.refund_record_count);
                    resolve(data);
                })
                .catch(({ response }) => {
                    this.context.commit(Mutations.SET_AI_LOADING, false);
                    this.context.commit(Mutations.SET_REPORT_ERROR, response);
                    reject(response);
                });
        });
    }

    // Action - EXPORT ACCOUNT MASTER REPORT LIST
    @Action
    [Actions.EXPORT_ACCOUNT_MASTER_REPORT_LIST](payload) {
        payload.page = 1;
        this.context.commit(Mutations.SET_AI_LOADING, true);

        return new Promise<any>((resolve, reject) => {

            const url = "/skye/reports/exports/account-master?page=" + payload.page;

            this.context.commit(Mutations.SET_DISABLE_BUTTON, true);
            ApiService.post(url, payload)
                .then(({ data }) => {
                    this.context.commit(Mutations.SET_AI_LOADING, false);
                    this.context.commit(Mutations.SET_REPORT_ERROR, []);
                    this.context.commit(Mutations.SET_EXPORT_ACCOUNT_MASTER_REPORT_LIST, data.data.data);
                    this.context.commit(Mutations.SET_DISABLE_BUTTON, false);

                    resolve(data);
                })
                .catch(({ response }) => {
                    this.context.commit(Mutations.SET_AI_LOADING, false);
                    this.context.commit(Mutations.SET_REPORT_ERROR, response);
                    reject(response);
                });
        });
    }

    @Action
  [Actions.GET_APPROVED_INVOICE_REPORT](payload) {
    this.context.commit(Mutations.SET_AI_LOADING, true);
    return new Promise<any>((resolve, reject) => {
        const url = "/skye/reports/approved-invoice?page=" + payload.page;
        ApiService.post(url, payload)
        .then(({ data }) => {
          debugger
          this.context.commit(Mutations.SET_AI_LOADING, false);
          this.context.commit(Mutations.SET_DI_LOADING, false);
          this.context.commit(Mutations.SET_INVOICE_LIST, data.data.invoices);
          resolve(data);

        })
        .catch(({ response }) => {// 
          this.context.commit(Mutations.SET_DI_LOADING, false);
          this.context.commit(Mutations.SET_AI_LOADING, false);
          this.context.commit(Mutations.SET_INVOICE_ERROR, response);
          reject(response);
        });
    });
  }
    // Action - EXPORT ACCOUNT MASTER REPORT LIST
    @Action
    [Actions.EXPORT_APPROVED_INVOICE_REPORT_LIST](payload) {
        payload.page=1;
        this.context.commit(Mutations.SET_AI_LOADING, true);
        return new Promise<any>((resolve, reject) => {

            const url = "/skye/reports/exports/approved-invoice?page=1";

            this.context.commit(Mutations.SET_DISABLE_BUTTON, true);
            ApiService.post(url, payload)
                .then(({ data }) => {
                    this.context.commit(Mutations.SET_AI_LOADING, false);
                    this.context.commit(Mutations.SET_REPORT_ERROR, []);
                    this.context.commit(Mutations.SET_EXPORT_APPROVED_INVOICE_REPORT_LIST, data.data.data);
                    this.context.commit(Mutations.SET_DISABLE_BUTTON, false);

                    resolve(data);
                })
                .catch(({ response }) => {
                    this.context.commit(Mutations.SET_AI_LOADING, false);
                    this.context.commit(Mutations.SET_REPORT_ERROR, response);
                    reject(response);
                });
        });
    }

    @Action
    [Actions.GET_QUOTE_AUTO_SUGGEST](payload) {
        return new Promise<any>((resolve, reject) => {

            const url = "/skye/reports/master/quote-auto-suggest";

            ApiService.post(url, payload)
                .then(({ data }) => {
                    this.context.commit(Mutations.SET_REPORT_ERROR, []);
                    this.context.commit(Mutations.SET_QUOTE_AUTO_SUGGEST, data.data);

                    resolve(data);
                })
                .catch(({ response }) => {
                    this.context.commit(Mutations.SET_REPORT_ERROR, response);
                    reject(response);
                });
        });
    }

    // Action - GET LEAD BY SOURCE REPORT LIST
    @Action
    [Actions.GET_LEAD_BY_SOURCE_REPORT_LIST](payload) {
        this.context.commit(Mutations.SET_AI_LOADING, true);
        return new Promise<any>((resolve, reject) => {

            const url = "/skye/reports/lead-by-source?page=" + payload.page;

            ApiService.post(url, payload)
                .then(({ data }) => {
                    this.context.commit(Mutations.SET_AI_LOADING, false);
                    this.context.commit(Mutations.SET_REPORT_ERROR, []);
                    this.context.commit(Mutations.SET_LEAD_BY_SOURCE_REPORT_LIST, data.data.report);
                    resolve(data);
                })
                .catch(({ response }) => {
                    this.context.commit(Mutations.SET_AI_LOADING, false);
                    this.context.commit(Mutations.SET_REPORT_ERROR, response);
                    reject(response);
                });
        });
    }

    // Action - EXPORT LEAD BY SOURCE REPORT LIST
    @Action
    [Actions.EXPORT_LEAD_BY_SOURCE_REPORT_LIST](payload) {
        this.context.commit(Mutations.SET_AI_LOADING, true);
        return new Promise<any>((resolve, reject) => {

            const url = "/skye/reports/exports/lead-by-source?page=" + payload.page;

            this.context.commit(Mutations.SET_DISABLE_BUTTON, true);
            ApiService.post(url, payload)
                .then(({ data }) => {
                    this.context.commit(Mutations.SET_AI_LOADING, false);
                    this.context.commit(Mutations.SET_REPORT_ERROR, []);
                    this.context.commit(Mutations.SET_EXPORT_LEAD_BY_SOURCE_REPORT_LIST, data.data.data);
                    this.context.commit(Mutations.SET_DISABLE_BUTTON, false);

                    resolve(data);
                })
                .catch(({ response }) => {
                    this.context.commit(Mutations.SET_AI_LOADING, false);
                    this.context.commit(Mutations.SET_REPORT_ERROR, response);
                    reject(response);
                });
        });
    }

    // Action - GET LEAD BY SOURCE FILTERS
    @Action
    [Actions.GET_LEAD_BY_SOURCE_FILTERS_LIST](payload) {
        return new Promise<any>((resolve, reject) => {

            const url = "/skye/reports/master/lead-by-source-filters";

            ApiService.post(url, payload)
                .then(({ data }) => {
                    this.context.commit(Mutations.SET_REPORT_ERROR, []);
                    this.context.commit(Mutations.SET_LEAD_BY_SOURCE_FILTERS_LIST, data.data.data);
                    this.context.commit(Mutations.SET_REPORT_YEARS_LIST, data.data.data.years);
                    this.context.commit(Mutations.SET_REPORT_MONTH_LIST, data.data.data.months);
                    this.context.commit(Mutations.SET_REPORT_LEAD_SOURCES_LIST, data.data.data.lead_sources);
                    this.context.commit(Mutations.SET_REPORT_UTM_SOURCES_LIST, data.data.data.utm_sources);
                    this.context.commit(Mutations.SET_REPORT_UTM_MEDIUM_LIST, data.data.data.utm_medium);
                    this.context.commit(Mutations.SET_REPORT_UTM_CAMPAIGN_LIST, data.data.data.utm_campaign);
                    this.context.commit(Mutations.SET_REPORT_UTM_CONTENT_LIST, data.data.data.utm_content);
                    this.context.commit(Mutations.SET_REPORT_LEAD_STATUS_LIST, data.data.data.lead_statuses);
                    resolve(data);
                })
                .catch(({ response }) => {
                    this.context.commit(Mutations.SET_REPORT_ERROR, response);
                    reject(response);
                });
        });
    }

    // Action - GET CLOSED LEAD REPORT LIST
     @Action
     [Actions.GET_CLOSED_LEAD_REPORT_LIST](payload) {
        this.context.commit(Mutations.SET_AI_LOADING, true);
         return new Promise<any>((resolve, reject) => {
 
             const url = "/skye/reports/closed-leads?page=" + payload.page;
 
             ApiService.post(url, payload)
                 .then(({ data }) => {
                    console.log('data.data.report',data.data.report)
                    this.context.commit(Mutations.SET_AI_LOADING, false);
                     this.context.commit(Mutations.SET_REPORT_ERROR, []);
                     this.context.commit(Mutations.SET_CLOSED_LEAD_REPORT_LIST, data.data.report);
                     resolve(data);
                 })
                 .catch(({ response }) => {
                    this.context.commit(Mutations.SET_AI_LOADING, false);
                     this.context.commit(Mutations.SET_REPORT_ERROR, response);
                     reject(response);
                 });
         });
    }

    
    @Action
     [Actions.GET_AL_MANARAH_REPORT](payload) {
        this.context.commit(Mutations.SET_AI_LOADING, true);
         return new Promise<void>((resolve, reject) => {
 
             const url = "/skye/reports/al-manarah-report?page=" + payload.page;
 
             ApiService.post(url, payload)
                 .then(({ data }) => {
                    this.context.commit(Mutations.SET_AI_LOADING, false);
                     this.context.commit(Mutations.SET_REPORT_ERROR, []);
                     this.context.commit(Mutations.SET_AL_MANARAH_REPORT, data.data.report);
                     resolve(data);
                 })
                 .catch(({ response }) => {
                    this.context.commit(Mutations.SET_AI_LOADING, false);
                     this.context.commit(Mutations.SET_REPORT_ERROR, response);
                     reject(response);
                 });
         });
    }

    @Action
     [Actions.GENERATE_AL_MANARAH_REPORT](payload) {
        // this.context.commit(Mutations.SET_AI_LOADING, true);
         return new Promise<void>((resolve, reject) => {
 
             const url = "/skye/reports/generate-al-manarah-report"
 
             ApiService.post(url, payload)
                 .then(({ data }) => {
                    // console.log('data.data.report',data.data.report)
                    // this.context.commit(Mutations.SET_AI_LOADING, false);
                    //  this.context.commit(Mutations.SET_REPORT_ERROR, []);
                     this.context.commit(Mutations.SET_GENERATE_AL_MANARAH_REPORT_RESPONSE, data.data.report);
                     resolve(data);
                 })
                 .catch(({ response }) => {
                    // this.context.commit(Mutations.SET_AI_LOADING, false);
                    //  this.context.commit(Mutations.SET_REPORT_ERROR, response);
                     reject(response);
                 });
         });
    }

     // Action - EXPORT AGENT SALES REPORT LIST
    @Action
    [Actions.EXPORT_CLOSED_LEADS_REPORT_LIST](payload) {
        this.context.commit(Mutations.SET_AI_LOADING, true);
        return new Promise<any>((resolve, reject) => {

            const url = "/skye/reports/exports/closed-leads";

            this.context.commit(Mutations.SET_DISABLE_BUTTON, true);
            ApiService.post(url, payload)
                .then(({ data }) => {
                    this.context.commit(Mutations.SET_AI_LOADING, false);
                    this.context.commit(Mutations.SET_REPORT_ERROR, []);
                    this.context.commit(Mutations.SET_EXPORT_CLOSED_LEADS_REPORT_LIST, data.data.data);
                    this.context.commit(Mutations.SET_DISABLE_BUTTON, false);

                    resolve(data);
                })
                .catch(({ response }) => {
                    this.context.commit(Mutations.SET_AI_LOADING, false);
                    this.context.commit(Mutations.SET_DISABLE_BUTTON, false);
                    this.context.commit(Mutations.SET_REPORT_ERROR, response);
                    reject(response);
                });
        });
    }

    // Action - GET DELETED LEAD REPORT LIST
    @Action
    [Actions.GET_DELETED_LEAD_REPORT_LIST](payload) {
        this.context.commit(Mutations.SET_AI_LOADING, true);
        return new Promise<any>((resolve, reject) => {

            const url = "/skye/reports/deleted-leads?page=" + payload.page;

            ApiService.post(url, payload)
                .then(({ data }) => {
                    this.context.commit(Mutations.SET_AI_LOADING, false);
                    this.context.commit(Mutations.SET_REPORT_ERROR, []);
                    this.context.commit(Mutations.SET_DELETED_LEAD_REPORT_LIST, data.data.report);
                    resolve(data);
                })
                .catch(({ response }) => {
                    this.context.commit(Mutations.SET_AI_LOADING, false);
                    this.context.commit(Mutations.SET_REPORT_ERROR, response);
                    reject(response);
                });
        });
    }

    // Action - EXPORT DELETED LEAD REPORT LIST
    @Action
    [Actions.EXPORT_DELETED_LEADS_REPORT_LIST](payload) {
        this.context.commit(Mutations.SET_AI_LOADING, true);
        return new Promise<any>((resolve, reject) => {

            const url = "/skye/reports/exports/deleted-leads";
            this.context.commit(Mutations.SET_DISABLE_BUTTON, true);
            ApiService.post(url, payload)
                .then(({ data }) => {
                    this.context.commit(Mutations.SET_AI_LOADING, false);
                    this.context.commit(Mutations.SET_REPORT_ERROR, []);
                    this.context.commit(Mutations.SET_EXPORT_CLOSED_LEADS_REPORT_LIST, data.data.data);
                    this.context.commit(Mutations.SET_DISABLE_BUTTON, false);

                    resolve(data);
                })
                .catch(({ response }) => {
                    this.context.commit(Mutations.SET_AI_LOADING, false);
                    this.context.commit(Mutations.SET_DISABLE_BUTTON, false);
                    this.context.commit(Mutations.SET_REPORT_ERROR, response);
                    reject(response);
                });
        });
    }

    // Action - GET DELETED LEAD REPORT LIST
    @Action
    [Actions.GET_DISCREPANCY_REPORT_LIST](payload) {
        this.context.commit(Mutations.SET_AI_LOADING, true);
        return new Promise<any>((resolve, reject) => {

            const url = "/skye/reports/discrepancy?page=" + payload.page;

            ApiService.post(url, payload)
                .then(({ data }) => {
                    this.context.commit(Mutations.SET_AI_LOADING, false);
                    this.context.commit(Mutations.SET_REPORT_ERROR, []);
                    this.context.commit(Mutations.SET_DISCREPANCY_REPORT_LIST, data.data.report);
                    resolve(data);
                })
                .catch(({ response }) => {
                    this.context.commit(Mutations.SET_AI_LOADING, false);
                    this.context.commit(Mutations.SET_REPORT_ERROR, response);
                    reject(response);
                });
        });
    }

    // Action - EXPORT DELETED LEAD REPORT LIST
    @Action
    [Actions.EXPORT_DISCREPANCY_REPORT_LIST](payload) {
        this.context.commit(Mutations.SET_AI_LOADING, true);
        return new Promise<any>((resolve, reject) => {

            const url = "/skye/reports/exports/discrepancy";
            this.context.commit(Mutations.SET_DISABLE_BUTTON, true);
            ApiService.post(url, payload)
                .then(({ data }) => {
                    this.context.commit(Mutations.SET_AI_LOADING, false);
                    this.context.commit(Mutations.SET_REPORT_ERROR, []);
                    this.context.commit(Mutations.SET_EXPORT_DISCREPANCY_REPORT_LIST, data.data.data);
                    this.context.commit(Mutations.SET_DISABLE_BUTTON, false);

                    resolve(data);
                })
                .catch(({ response }) => {
                    this.context.commit(Mutations.SET_AI_LOADING, false);
                    this.context.commit(Mutations.SET_DISABLE_BUTTON, false);
                    this.context.commit(Mutations.SET_REPORT_ERROR, response);
                    reject(response);
                });
        });
    }

    // Action - GET ADMIN DASHBOARD REPORT
    @Action
    [Actions.GET_ADMIN_DASHBOARD_REPORT](payload) {
        this.context.commit(Mutations.SET_AI_LOADING, true);
        return new Promise<any>((resolve, reject) => {

            const url = "/skye/reports/dashboard-reports";
            ApiService.post(url, payload)
                .then(({ data }) => {
                    this.context.commit(Mutations.SET_AI_LOADING, false);
                    this.context.commit(Mutations.SET_REPORT_ERROR, []);
                    let report = data.data.report
                    if(typeof report == 'object') report = Object.values(report)
                    this.context.commit(Mutations.SET_ADMIN_DASHBOARD_REPORT, report);
                    resolve(data);
                })
                .catch(({ response }) => {
                    this.context.commit(Mutations.SET_AI_LOADING, false);
                    this.context.commit(Mutations.SET_REPORT_ERROR, response);
                    reject(response);
                });
        });
    }
    @Action
    [Actions.EXPORT_ADMIN_DASHBOARD_REPORT](payload) {
        this.context.commit(Mutations.SET_AI_LOADING, true);
        return new Promise<any>((resolve, reject) => {

            const url = "/skye/reports/exports/dashboard-reports";
            this.context.commit(Mutations.SET_DISABLE_BUTTON, true);
            ApiService.post(url, payload)
                .then(({ data }) => {
                    this.context.commit(Mutations.SET_AI_LOADING, false);
                    this.context.commit(Mutations.SET_REPORT_ERROR, []);
                    this.context.commit(Mutations.SET_ADMIN_DASHBOARD_EXPORT, data.data.data);
                    this.context.commit(Mutations.SET_DISABLE_BUTTON, false);
                    resolve(data);
                })
                .catch(({ response }) => {
                    this.context.commit(Mutations.SET_AI_LOADING, false);
                    this.context.commit(Mutations.SET_DISABLE_BUTTON, false);
                    this.context.commit(Mutations.SET_REPORT_ERROR, response);
                    reject(response);
                });
        });
    }

    // Action - GET DASHBOARD DETAILS REPORT LIST
    @Action
    [Actions.GET_DASHBOARD_DETAILS_REPORT_LIST](payload) {
        this.context.commit(Mutations.SET_AI_LOADING, true);
        return new Promise<any>((resolve, reject) => {

            const url = "/skye/reports/dashboard-details?page=" + payload.page;

            ApiService.post(url, payload)
                .then(({ data }) => {
                    this.context.commit(Mutations.SET_AI_LOADING, false);
                    this.context.commit(Mutations.SET_REPORT_ERROR, []);
                    this.context.commit(Mutations.SET_DASHBOARD_DETAILS_REPORT_LIST, data.data.report);
                    resolve(data);
                })
                .catch(({ response }) => {
                    this.context.commit(Mutations.SET_AI_LOADING, false);
                    this.context.commit(Mutations.SET_REPORT_ERROR, response);
                    reject(response);
                });
        });
    }
    // Action - EXPORT LEAD BY SOURCE REPORT LIST
    @Action
    [Actions.EXPORT_DASHBOARD_DETAILS_REPORT_LIST](payload) {
        this.context.commit(Mutations.SET_AI_LOADING, true);
        return new Promise<any>((resolve, reject) => {

            const url = "/misk-skye/reports/exports/dashboard-details?page=" + payload.page;

            this.context.commit(Mutations.SET_DISABLE_BUTTON, true);
            ApiService.post(url, payload)
                .then(({ data }) => {
                    this.context.commit(Mutations.SET_AI_LOADING, false);
                    this.context.commit(Mutations.SET_REPORT_ERROR, []);
                    this.context.commit(Mutations.SET_EXPORT_DASHBOARD_DETAILS_REPORT_LIST, data.data.data);
                    this.context.commit(Mutations.SET_DISABLE_BUTTON, false);

                    resolve(data);
                })
                .catch(({ response }) => {
                    this.context.commit(Mutations.SET_AI_LOADING, false);
                    this.context.commit(Mutations.SET_REPORT_ERROR, response);
                    reject(response);
                });
        });
    }

    // Action - GET LEAD BY SOURCE FILTERS
    @Action
    [Actions.GET_LEAD_SOURCE_FILTER_LIST](payload) {
        return new Promise<any>((resolve, reject) => {

            const url = "/skye/reports/master/lead-source-filter";

            ApiService.post(url, payload)
                .then(({ data }) => {
                    this.context.commit(Mutations.SET_REPORT_ERROR, []);
                    // this.context.commit(Mutations.SET_LEAD_BY_SOURCE_FILTERS_LIST, data.data.data);
                    
                    this.context.commit(Mutations.SET_REPORT_LEAD_SOURCES_LIST, data.data.data);
                 
                    resolve(data);
                })
                .catch(({ response }) => {
                    this.context.commit(Mutations.SET_REPORT_ERROR, response);
                    reject(response);
                });
        });
    }
        
    

    // Action - GET DELETED LEAD REPORT LIST
    @Action
    [Actions.GET_CUSTOMER_WITHOUT_LEAD_REPORT_LIST](payload) {
        this.context.commit(Mutations.SET_AI_LOADING, true);
        return new Promise<any>((resolve, reject) => {

            const url = "/misk-skye/reports/customers-without-lead?page=" + payload.page;

            ApiService.post(url, payload)
                .then(({ data }) => {
                    this.context.commit(Mutations.SET_AI_LOADING, false);
                    this.context.commit(Mutations.SET_REPORT_ERROR, []);
                    this.context.commit(Mutations.SET_CUSTOMER_WITHOUT_LEAD_REPORT_LIST, data.data.customer);
                    resolve(data);
                })
                .catch(({ response }) => {
                    this.context.commit(Mutations.SET_AI_LOADING, false);
                    this.context.commit(Mutations.SET_REPORT_ERROR, response);
                    reject(response);
                });
        });
    }

    // Action - EXPORT DELETED LEAD REPORT LIST
    @Action
    [Actions.EXPORT_CUSTOMER_WITHOUT_LEADS_REPORT_LIST](payload) {
        this.context.commit(Mutations.SET_AI_LOADING, true);
        return new Promise<any>((resolve, reject) => {

            const url = "/misk-skye/reports/exports/customers-without-lead";
            this.context.commit(Mutations.SET_DISABLE_BUTTON, true);
            ApiService.post(url, payload)
                .then(({ data }) => {
                    this.context.commit(Mutations.SET_AI_LOADING, false);
                    this.context.commit(Mutations.SET_REPORT_ERROR, []);
                    this.context.commit(Mutations.SET_EXPORT_CUSTOMER_WITHOUT_LEADS_REPORT_LIST, data.data.data);
                    this.context.commit(Mutations.SET_DISABLE_BUTTON, false);

                    resolve(data);
                })
                .catch(({ response }) => {
                    this.context.commit(Mutations.SET_AI_LOADING, false);
                    this.context.commit(Mutations.SET_DISABLE_BUTTON, false);
                    this.context.commit(Mutations.SET_REPORT_ERROR, response);
                    reject(response);
                });
        });
    }
        
    /**
     * MUTATION
     * 
     * The only way to change the state in a Vuex store is by committing a mutation. 
     * Vuex mutations are very similar to events: each mutation has a string type 
     * and a handler. The handler function is where we perform actual state modifications, 
     * and it will receive the state as the first argument.
     */
    @Mutation
    [Mutations.SET_REPORT_ERROR](error) {
        const errors = [] as any;
        for (const key in error) {
            errors.push(error[key]);
        }
        this.errors = errors;
    }

    @Mutation
    [Mutations.SET_POLICY_REPORT_LIST](payload) {
        this.policyReportList = payload.data;
        // setting below vars for pagination
        delete payload.data;
        this.paginationObj = payload;
    }

    @Mutation
    [Mutations.SET_EXPORT_POLICY_REPORT_LIST](payload) {
        this.export = payload;
    }

    @Mutation
    [Mutations.SET_AGENT_SALES_REPORT_LIST](payload) {
        this.agentSalesReportList = payload.data;
        // setting below vars for pagination
        delete payload.data;
        this.paginationObj = payload;
    }

    @Mutation
    [Mutations.SET_EXPORT_AGENT_SALES_REPORT_LIST](payload) {
        this.export = payload;
    }

    @Mutation
    [Mutations.SET_SKYE_LOGIN_INFO_REPORT_LIST](payload) {
        this.skyeLoginInfoReportList = payload.data;
        // setting below vars for pagination
        delete payload.data;
        this.paginationObj = payload;
    }

    @Mutation
    [Mutations.SET_EXPORT_SKYE_LOGIN_INFO_REPORT_LIST](payload) {
        this.export = payload;
    }

    @Mutation
    [Mutations.SET_ACCOUNT_MASTER_REPORT_LIST](payload) {
        this.accountMasterReportList = payload.data;
        // setting below vars for pagination
        delete payload.data;
        this.paginationObj = payload;
    }

    @Mutation
    [Mutations.SET_ACCOUNT_MASTER_REFUND_RECORD_COUNT](payload) {
        this.accountMasterRefundRecordCount = payload;
    }

    @Mutation
    [Mutations.SET_EXPORT_ACCOUNT_MASTER_REPORT_LIST](payload) {
        this.export = payload;
    }

    @Mutation
    [Mutations.SET_EXPORT_APPROVED_INVOICE_REPORT_LIST](payload) {
        this.export = payload;
    }

    @Mutation
    [Mutations.SET_QUOTE_AUTO_SUGGEST](payload) {
        this.quoteAutoSuggestList = payload.data;
    }

    @Mutation
    [Mutations.SET_LEAD_BY_SOURCE_REPORT_LIST](payload) {
        this.leadBySourceReportList = payload.data;
        // setting below vars for pagination
        delete payload.data;
        this.paginationObj = payload;
    }

    @Mutation
    [Mutations.SET_EXPORT_LEAD_BY_SOURCE_REPORT_LIST](payload) {
        this.export = payload;
    }

    @Mutation
    [Mutations.SET_LEAD_BY_SOURCE_FILTERS_LIST](payload) {
        this.leadBySourceFiltersList = payload;
    }

    @Mutation
    [Mutations.SET_REPORT_YEARS_LIST](payload) {
        this.getReportYears = payload;
    }

    @Mutation
    [Mutations.SET_REPORT_MONTH_LIST](payload) {
        this.getReportMonths = payload;
    }

    @Mutation
    [Mutations.SET_REPORT_LEAD_SOURCES_LIST](payload) {
        this.getReportLeadSources = payload;
    }

    @Mutation
    [Mutations.SET_REPORT_UTM_SOURCES_LIST](payload) {
        this.getReportUTMSources = payload;
    }

    @Mutation
    [Mutations.SET_REPORT_UTM_MEDIUM_LIST](payload) {
        this.getReportUTMMedium = payload;
    }

    @Mutation
    [Mutations.SET_REPORT_UTM_CAMPAIGN_LIST](payload) {
        this.getReportUTMCampaign = payload;
    }

    @Mutation
    [Mutations.SET_REPORT_UTM_CONTENT_LIST](payload) {
        this.getReportUTMContent = payload;
    }


    @Mutation
    [Mutations.SET_CLOSED_LEAD_REPORT_LIST](payload) {
        this.closedLeadReportList = payload.data;
        // setting below vars for pagination
        delete payload.data;
        this.paginationObj = payload;
    }

    @Mutation
    [Mutations.SET_AL_MANARAH_REPORT](payload) {
        this.alManarahReportList = payload.data;
        // setting below vars for pagination
        delete payload.data;
        this.paginationObj = payload;
    }

    
    @Mutation
    [Mutations.SET_EXPORT_CLOSED_LEADS_REPORT_LIST](payload) {
        this.export = payload;
    }
    @Mutation
    [Mutations.SET_GENERATE_AL_MANARAH_REPORT_RESPONSE](payload) {
        this.almanarahResponse = payload;
    }


    @Mutation
    [Mutations.SET_DELETED_LEAD_REPORT_LIST](payload) {
        this.deletedLeadReportList = payload.data;
        // setting below vars for pagination
        delete payload.data;
        this.paginationObj = payload;
    }

    @Mutation
    [Mutations.SET_EXPORT_DELETED_LEADS_REPORT_LIST](payload) {
        this.export = payload;
    }

    @Mutation
    [Mutations.SET_CUSTOMER_WITHOUT_LEAD_REPORT_LIST](payload) {
        console.log(payload.data);
        this.customerWithoutLeadReportList = payload.data;
        // setting below vars for pagination
        delete payload.data;
        this.paginationObj = payload;
    }
    
    
    @Mutation
    [Mutations.SET_EXPORT_CUSTOMER_WITHOUT_LEADS_REPORT_LIST](payload) {
        this.export = payload;
    }
    
    @Mutation
    [Mutations.SET_DISCREPANCY_REPORT_LIST](payload) {
        this.discrepancyReportList = payload.data;
        // setting below vars for pagination
        delete payload.data;
        this.paginationObj = payload;
    }

    @Mutation
    [Mutations.SET_EXPORT_DISCREPANCY_REPORT_LIST](payload) {
        this.export = payload;
    }
    
    @Mutation
    [Mutations.SET_ADMIN_DASHBOARD_REPORT](payload) {
        this.adminDashboardData = payload;
    }
    @Mutation
    [Mutations.SET_ADMIN_DASHBOARD_EXPORT](payload) {
        this.adminDashboardExportUrl = payload;
    }


    @Mutation
    [Mutations.SET_REPORT_LEAD_STATUS_LIST](payload) {
        this.reportLeadStatuses = payload;
    }


    @Mutation
    [Mutations.SET_DASHBOARD_DETAILS_REPORT_LIST](payload) {
        this.dashboardDetailsData = payload.data;
        // setting below vars for pagination
        delete payload.data;
        this.paginationObj = payload;
    }

    @Mutation
    [Mutations.SET_EXPORT_DASHBOARD_DETAILS_REPORT_LIST](payload) {
        this.export = payload;
    }
      
    @Mutation
    [Mutations.SET_AI_LOADING](payload) {
        this.arLoading = payload
    }
    
    /**
     * GETTERS
     * 
     * Getter used to fetch variable value.
     */
    get getCommonPayLoad() {
        return this.commonPayLoad;
    }

    get getCommonPayLoadLBS() {
        // for lead by source report
        return this.commonPayLoadLBS;
    }

    get getPolicyReport() {
        return this.policyReportList;
    }

    get getAgentSalesReport() {
        return this.agentSalesReportList;
    }

    get getSkyeLoginInfoReports() {
        return this.skyeLoginInfoReportList;
    }

    get getAccountMasterReport() {
        return this.accountMasterReportList;
    }

    get getAccountMasterRefundRecordCount() {
        return this.accountMasterRefundRecordCount;
    }

    get getQuoteSuggest() {
        return this.quoteAutoSuggestList;
    }

    get getLeadBySourceReport() {
        return this.leadBySourceReportList;
    }

    get getLBSYears() {
        return this.getReportYears;
    }

    get getLBSMonths() {
        return this.getReportMonths;
    }

    get getLBSSources() {
        return this.getReportLeadSources;
    }

    get getLBSUTMSources() {
        return this.getReportUTMSources;
    }

    get getLBSUTMMedium() {
        return this.getReportUTMMedium;
    }

    get getLBSUTMCampaign() {
        return this.getReportUTMCampaign;
    }

    get getLBSUTMContent() {
        return this.getReportUTMContent;
    }

    get getExportPagination() {
        return this.paginationObj;
    }

    get getExport() {
        return this.export;
    }
    get getAlmanarahResponse() {
        return this.almanarahResponse;
    }

    get getClosedLeadReport() {
        return this.closedLeadReportList;
    }

    get getAlManarahReport() {
        return this.alManarahReportList;
    }

    


    get getDeletedLeadReport() {
        return this.deletedLeadReportList;
    }

    get getDiscrepancyReport() {
        return this.discrepancyReportList;
    }
    get getAdminDashboardData() {
        return this.adminDashboardData;
    }
    get getAdminDashboardExportUrl(){
        return this.adminDashboardExportUrl;
    }

    get getReportLeadStatuses() {
        return this.reportLeadStatuses;
    }

    get getDashboardDetails() {
        return this.dashboardDetailsData;
    }

    get getCustomerWithoutLeadReport() {
        return this.customerWithoutLeadReportList;
    }
    
    get getARLoading() {
        return this.arLoading;
    }
}
