<template>
    <div class="card">
        <div :class="`card-header boder-0 pt-5 ${backgroundColor}`">
            <h3 class="card-title align-items-start flex-column">
                <span class="card-label fw-bolder fs-3 mb-1 statistics-card-label">
                    {{ title }}
                </span>
            </h3>
        </div>
        <template v-if="loading">
            <div class="card-body pie-chart-container" v-if="series && series.length > 0">
                <VueApexCharts
                type="pie"
                :width="chartOptions.chart.width"
                :height="chartOptions.chart.height"
                :options="chartOptions"
                :series="series"
                ></VueApexCharts>
            </div>
            <div class="card-body" v-else>
                <el-empty description="No Records Available" />
            </div>
        </template>
        <template v-else>
            <span>
                Please wait...
                <span
                class="spinner-border spinner-border-sm align-middle ms-2"
                ></span>
            </span>
        </template>
    </div>
</template>
  
  <script>
  import { defineComponent } from "vue";
  import VueApexCharts from "vue3-apexcharts";
  
  export default defineComponent({
    props: {
      loading: [],
      chartOptions: {},
      series: [],
      title: String,
      backgroundColor: {
        type: String,
        required: false,
        default: "#fff"
      },
      fontColor: {
        type: String,
        required: false,
        default: "#000"
      }
    },
    components: {
      VueApexCharts
    }
  });
  </script>
  