import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import LocalStorageServices from "@/core/services/LocalStorageServices";
import router from "@/router";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import axios from "axios";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import { notif } from '@/store/stateless/store';
import { ElNotification } from "element-plus";

export interface User {
  name: string;
  surname: string;
  email: string;
  password: string;
  access_token: string;
}
export interface AclPermission {
  is_admin: boolean;
  is_team_leader: boolean;
  is_sales_agent: boolean;
  is_accountant: boolean;
  is_underwritter: boolean;
  is_marketing: boolean;
  is_manager: boolean;
  is_qca: boolean;

}
export interface Route {
  id: string;
  title: string;
  route: string;
  svgIcon: string;
  fontIcon: string;
  parent: string;
}
export interface UserAuthInfo {
  errors: Array<string>;
  user: User;
  aclPermission: AclPermission;
  isAuthenticated: boolean;
  routes: Array<Route>;
}


export interface NewCheckAuth {
  auth_check_status: boolean;
  checked_at: string;
}

@Module
export default class AuthModule extends VuexModule implements UserAuthInfo {
  errors = [];
  user = {} as User;
  aclPermission = {} as AclPermission;
  isAuthenticated = !!JwtService.getToken();
  routes = [];
  loginError = ""
  refreshTime = 5;
  setNewAuthCheck = {} as NewCheckAuth

  /**
   * Get current user object
   * @returns User
   */
  get currentUser() {
    return this.user;
  }

  /**
   * Verify user authentication
   * @returns boolean
   */
  get isUserAuthenticated(): boolean {
    return this.isAuthenticated;
  }

  get getRefreshTime() {
    return this.refreshTime;
  }

  /**
   * Get authentification errors
   * @returns array
   */
  get getAuthErrors(): Array<string> {
    return this.errors;
  }

  get getUserPermissions(): AclPermission {
    return this.aclPermission;
  }

  get getLoginError() {
    return this.loginError;
  }

  get getRouteList() {
    return this.routes;
  }
  @Mutation
  [Mutations.SET_ERROR](error) {
    
    this.errors = error;
  }
  @Mutation
  [Mutations.SET_ERRORS](error) {
    
    this.errors = error;
  }

  @Mutation
  [Mutations.SET_AUTH](user) {
    this.isAuthenticated = true;
    this.user = user.data.user;
    this.user.access_token = user.data.access_token; // set the token to user interface
    this.errors = [];
    console.log({user})
    // JwtService.saveToken(this.user.access_token);
    // window.localStorage.setItem('refresh_token', user.data.refresh_token)
    LocalStorageServices.saveUser(JSON.stringify(this.user));
  }

  @Mutation
  [Mutations.SET_USER](user: User) {
    this.user = user;
  }

  @Mutation
  [Mutations.SET_PASSWORD](password) {
    this.user.password = password;
  }

  @Mutation
  [Mutations.SET_AUTHENTICATED](payload) {
    this.isAuthenticated = payload
  }

  @Mutation
  [Mutations.SET_REFRESH_TIME](payload) {
    this.refreshTime = payload
  }

  @Mutation
  async [Mutations.PURGE_AUTH]() {
    window.localStorage.removeItem("id_token");
    window.localStorage.removeItem("myRoute");
    window.localStorage.removeItem("currentRoute");
    window.localStorage.removeItem("user");
    // window.localStorage.removeItem("currentRoute");
    // window.localStorage.clear();
    this.user = {} as User;
    this.routes = [];
    this.isAuthenticated = false;
    this.errors = [];
    this.aclPermission = {} as AclPermission;
    window.location.reload();
    setTimeout(() => {
      router.push('/sign-in');
    }, 1000);
    // this.isAuthenticated = false;
    // this.user = {} as User;
    // this.errors = [];
    // JwtService.destroyToken();
    // LocalStorageServices.destroyUser();
  }

  @Mutation
  [Mutations.PURGE_AUTH_SIGNIN]() {
    window.localStorage.removeItem("id_token");
    window.localStorage.removeItem("myRoute");
    window.localStorage.removeItem("user");
    this.user = {} as User;
    this.routes = [];
    this.isAuthenticated = false;
    this.errors = [];
    this.aclPermission = {} as AclPermission;
    // this.isAuthenticated = false;
    // this.user = {} as User;
    // this.errors = [];
    // JwtService.destroyToken();
    // LocalStorageServices.destroyUser();
  }

  @Mutation
  ['SESSION_DELETED']() {
    window.localStorage.removeItem("id_token");
    window.localStorage.removeItem("myRoute");
    window.localStorage.removeItem("user");
    this.user = {} as User;
    this.routes = [];
    this.isAuthenticated = false;
    this.errors = [];
    this.aclPermission = {} as AclPermission;
    window.location.reload()
  }

  @Mutation
  [Mutations.SET_USER_ACL](user) {
    
    if(Object.keys(user).length > 0) {
      switch (user.data.user.role_id) {
        case 1:
          this.aclPermission.is_admin = true;
          break;
        case 2:
          this.aclPermission.is_sales_agent = true;
          break;
        case 13:
          this.aclPermission.is_team_leader = true;
          break;
        case 4:
          this.aclPermission.is_underwritter = true;
          break;
        case 7:
          this.aclPermission.is_marketing = true;
          break;
        case 6:
          this.aclPermission.is_accountant = true;
          break;
        case 9:
          this.aclPermission.is_manager = true;
          break;
        case 11:
            this.aclPermission.is_marketing = true;
            break;
        case 14:
          this.aclPermission.is_qca = true;
          break;
      }
    } else {
      this.aclPermission.is_manager = this.aclPermission.is_admin = this.aclPermission.is_sales_agent = this.aclPermission.is_team_leader = this.aclPermission.is_underwritter = this.aclPermission.is_marketing = this.aclPermission.is_accountant = this.aclPermission.is_qca = false;
    }
  }

  @Mutation
  [Mutations.SET_ROUTE_LIST](data) {
    this.routes = data;
  }
  
  @Mutation
  [Mutations.SET_ROUTE_LIST_INITIAL](payload) {
    this.routes = payload
  }
  
  @Mutation
  [Mutations.SET_LOGIN_ERROR](payload) {
    this.loginError = payload
  }

  @Mutation
  [Mutations.SET_NEW_AUTH_CHECK](data) {
    this.setNewAuthCheck = data;
  }

  @Action
  [Actions.LOGIN](credentials) {
    credentials.app = 'misk';
    return new Promise<any>((resolve, reject) => {
      ApiService.post("/skye/login", credentials)
        .then((data) => {
          this.context.commit(Mutations.SET_AUTH, data.data);
          this.context.commit(Mutations.SET_USER_ACL, data.data)
          this.context.commit(Mutations.SET_LOGIN_ERROR, "")
          JwtService.saveToken(data.data.data.access_token);
          window.localStorage.setItem('refresh_token', data.data.data.refresh_token)
          window.localStorage.setItem('expires_in', data.data.data.expires_in)
          resolve(data);
        })
        .catch((error) => {
          // notif.simple('Login', 'warning', error.response.data.message, 0);
          this.context.commit(Mutations.SET_LOGIN_ERROR, error.response.data.message)
          // this.context.commit(Mutations.SET_ERROR, error.response.data.message);
          reject(error);
        });
    });
  }

  @Action
  async [Actions.LOGOUT](payload) {
    await ApiService.post('/skye/logout', payload)
    this.context.commit(Mutations.PURGE_AUTH);
    // return new Promise((res, rej) => {
      
    //   ApiService.post_get('/skye/logout')
    //   // window.localStorage.clear();
    //   this.context.commit(Mutations.PURGE_AUTH);
    //   // .then((result) => {
    //   //   this.context.commit(Mutations.SET_USER, {})
    //   //   this.context.commit(Mutations.SET_ROUTE_LIST_INITIAL, [])
    //   //   this.context.commit(Mutations.SET_AUTHENTICATED, false)
    //   //   this.context.commit(Mutations.SET_ERRORS, []);
    //   //   res(result)
    //   // }).catch((err) => {
    //   //   this.context.commit(Mutations.PURGE_AUTH);
    //   //   this.context.commit(Mutations.SET_USER, {})
    //   //   this.context.commit(Mutations.SET_ROUTE_LIST_INITIAL, [])
    //   //   this.context.commit(Mutations.SET_AUTHENTICATED, false)
    //   //   this.context.commit(Mutations.SET_ERRORS, []);
    //   //   rej(err)
    //   // })
    // })
  }

  @Action
  async [Actions.SWITCH_ROLE](payload) {
    return new Promise<any>((resolve, reject) => {
      ApiService.post("/skye/change-role", payload)
        .then((data) => {
          // this.context.commit(Mutations.SET_AUTH, data.data);
          this.context.commit(Mutations.SET_USER_ACL, data.data)
          JwtService.saveToken(data.data.data.access_token);
          window.localStorage.setItem('refresh_token', data.data.data.refresh_token)
          window.localStorage.setItem('expires_in', data.data.data.expires_in)
          // this.context.commit(Mutations.SET_LOGIN_ERROR, "")

          resolve(data);
        })
        .catch((error) => {
          // notif.simple('Login', 'warning', error.response.data.message, 0);
          // this.context.commit(Mutations.SET_LOGIN_ERROR, error.response.data.message)
          // this.context.commit(Mutations.SET_ERROR, error.response.data.message);
          reject(error);
        });
    });
  }

  @Action
  [Actions.REGISTER](credentials) {
    return new Promise<any>((resolve, reject) => {
      ApiService.post("registration", credentials)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_AUTH, data);
          resolve(data);
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
          // reject();
        });
    });
  }

  @Action
  [Actions.FORGOT_PASSWORD](payload) {
    return new Promise<any>((resolve, reject) => {
      ApiService.post("forgot_password", payload)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_AUTH, data);
          resolve(data);
        })
        .catch(({ response }) => {

          this.context.commit(Mutations.SET_ERROR, response.data.errors);
          // reject();
        });
    });
  }

  @Action
  [Actions.VERIFY_AUTH]() {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      let payload: any;
      ApiService.post("/skye/user-details", payload)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_AUTH, data);
          this.context.commit(Mutations.SET_USER_ACL, data)
        })
        .catch(({ response }) => {
          // this.context.commit(Mutations.PURGE_AUTH);
          // this.context.commit(Mutations.PURGE_AUTH);
          if (!response == undefined)
            this.context.commit(Mutations.SET_ERROR, response.data.errors);
        });
    } else {
      // router.push('/sign-in');
      // this.context.commit(Mutations.PURGE_AUTH);
      // this.context.commit(Mutations.PURGE_AUTH);
    }
  }

  @Action
  [Actions.UPDATE_USER](payload) {
    ApiService.setHeader();
    return new Promise<any>((resolve, reject) => {
      ApiService.post("update_user", payload)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_USER, data);
          resolve(data);
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
          // reject();
        });
    });
  }

  @Action
  [Actions.GET_ROUTE_LIST](payload) {
    if (JwtService.getToken()) {
      return new Promise((resolve, reject) => {
        ApiService.post("/skye/master/front-routes", payload)
          .then(({ data }) => {
            this.context.commit(Mutations.SET_ROUTE_LIST, data.data.routes);
            window.localStorage.setItem('myRoute', JSON.stringify(data.data.routes))
            resolve(data);
          })
          .catch((error) => {
            
            if(ApiService.errUndefinedCheck(error)){
              /**
               * Commented by : jeybin
               * date         : 24/07/2023
               * The localstorage was clearing on multiple refresh
               * this cause the logout from the frontend with an 
               * active backend token  
               */
              // router.push('/sign-in')
              // console.log("AUTHMODULES line 388");
              // window.localStorage.removeItem('myRoute');
              // window.localStorage.removeItem('refresh_token');
              // window.localStorage.removeItem('expires_in');
              // window.localStorage.removeItem('id_token');
              // window.localStorage.removeItem('user');
              reject(error);
            }
          });
      });
    }
  }

  @Action
  async[Actions.CHECK_TOKEN](payload) {

    try {
      let url = '/skye/check-token'
      if(payload.uid) url = '/skye/check-token-unauth'
      payload.access_token = window.localStorage.getItem('id_token') || ''
      const response = await ApiService.post(url, payload)
      if(response.data.access_token) {
        JwtService.saveToken(response.data.access_token);
        window.localStorage.setItem('refresh_token', response.data.refresh_token)
        window.localStorage.setItem('expires_in', response.data.expires_in)
      }
      if(response.data.expires_at) {
        window.localStorage.setItem('settimeout', response.data.expires_at)
      }
      return response
    } catch (error) {
      this.context.commit(Mutations.SET_USER, {});
        this.context.commit(Mutations.SET_USER_ACL, {});
        window.localStorage.removeItem('id_token');
        window.localStorage.removeItem('refresh_token');
        window.localStorage.removeItem('expires_in');
        window.localStorage.removeItem('user');
        window.localStorage.removeItem('myRoute');
        router.push({name: 'sign-in'})
        ElNotification.closeAll();
      return error
    }
    
    // ApiService.post('/skye/check-token', payload)
    // .then((result) => {
    //   if(result.data.access_token) {
    //     JwtService.saveToken(result.data.access_token);
    //     window.localStorage.setItem('refresh_token', result.data.refresh_token)
    //     window.localStorage.setItem('expires_in', result.data.expires_in)
    //   }

    //   if(result.data.expires_at) {
    //     window.localStorage.setItem('settimeout', result.data.expires_at)
    //   }
    //   // router.push("/")
    //   // window.location.reload();
    // })
    // .catch((err) => {
    //   // if(err.response.data.data == 401) {
    //     // window.localStorage.clear();
    //     this.context.commit(Mutations.SET_USER, {});
    //     this.context.commit(Mutations.SET_USER_ACL, {});
    //     window.localStorage.removeItem('id_token');
    //     window.localStorage.removeItem('refresh_token');
    //     window.localStorage.removeItem('expires_in');
    //     window.localStorage.removeItem('user');
    //     window.localStorage.removeItem('myRoute');
    //     router.push({name: 'sign-in'})
    //     ElNotification.closeAll();
    //   // } 
    // })
  }

  @Action
  [Actions.REFRESH_TIME]() {
    ApiService.get('/skye/get-refresh-front-end')
    .then((result) => {
      this.context.commit(Mutations.SET_REFRESH_TIME, result.data)
      window.localStorage.setItem('refresh_time', result.data)
    })    
    .catch((error)=>{
      if(ApiService.errUndefinedCheck(error)){
        console.log(error);
      }
    });
  }



  @Action
  [Actions.SWITCH_APP](payload) {
    return new Promise<any>((resolve, reject) => {
      ApiService.post("/skye/switch-app", payload)
        .then((data) => {
          this.context.commit(Mutations.SET_AUTH, data.data);
          this.context.commit(Mutations.SET_USER_ACL, data.data)
          this.context.commit(Mutations.SET_LOGIN_ERROR, "")
          JwtService.saveToken(data.data.data.access_token);
          window.localStorage.setItem('refresh_token', data.data.data.refresh_token)
          window.localStorage.setItem('expires_in', data.data.data.expires_in)
          resolve(data);
          return data;
        })
        .catch((error) => {
          localStorage.clear();
          this.context.commit(Mutations.SET_AUTH, null);
          router.push({name: 'sign-in'})
        });
    });
  }
  
  @Action
  [Actions.SWITCH_APP_RELOAD](payload) {
    return new Promise<any>((resolve, reject) => {
      ApiService.post("/skye/switch-reload", payload)
        .then((data) => {
          resolve(data);
          return data;
        })
        .catch((error) => {
          // window.location.reload()
        });
    });
  }
    
  @Action
  [Actions.GET_NEW_AUTH_CHECK](payload) {
    return new Promise<any>((resolve, reject) => {
      ApiService.post("/skye/token-check", payload)
        .then((data) => {
          this.context.commit(Mutations.SET_NEW_AUTH_CHECK, data.data.data.auth_check_status);
          if(data.data.data.auth_check_status){
            window.localStorage.setItem('token_lc', JSON.stringify(data.data.data.checked_at))
          }else{
            this.context.commit(Mutations.PURGE_AUTH_SIGNIN)
            window.location.reload();
          }
        })
        .catch((error) => {
          // window.location.reload()
        });
    });

  }

}
